import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApprovalPandding from './ApprovalPandding';
import ApiService from '../../../services/Api'; 

const ApplyMerchant = () => {
  const [formData, setFormData] = useState({
    shop_name: '',
    trading_year: '',
    registration_number: '',
    owner_name: '',
    address: '',
    website: '',
    documents: [], 
    logo: null,
  });

  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    setSubmitError('');
  };

  const handleDocumentChange = (e) => {
    const { files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      documents: Array.from(files), 
    }));
    setErrors((prevErrors) => ({ ...prevErrors, documents: '' }));
    setSubmitError('');
  };

  // const handleLogoChange = (e) => {
  //   const { files } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     logo: files[0], 
  //   }));
  //   setErrors((prevErrors) => ({ ...prevErrors, logo: '' }));
  //   setSubmitError('');
  // };

  const validateForm = () => {
    const newErrors = {};
    const urlPattern = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,}(:\d+)?(\/.*)?$/i;

    if (!formData.shop_name) newErrors.shop_name = 'Shop name is required';
    if (!formData.trading_year) newErrors.trading_year = 'Year is required';
    if (!formData.registration_number) newErrors.registration_number = 'Registration number is required';
    if (!formData.owner_name) newErrors.owner_name = 'Owner name is required';
    if (!formData.address) newErrors.address = 'Address is required';
    if (!formData.website) {
      newErrors.website = 'Website is required';
    } else if (!urlPattern.test(formData.website)) {
      newErrors.website = 'Website must be a valid URL';
    }
    if (formData.documents.length === 0) {
      newErrors.documents = 'At least one document must be uploaded'; 
    }
    // if (!formData.logo) {
    //   newErrors.logo = 'Logo upload is required'; 
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const formDataToSend = new FormData();
    formDataToSend.append('shop_name', formData.shop_name);
    formDataToSend.append('trading_year', formData.trading_year);
    formDataToSend.append('registration_number', formData.registration_number);
    formDataToSend.append('owner_name', formData.owner_name);
    formDataToSend.append('address', formData.address);
    formDataToSend.append('website', formData.website);

    formData.documents.forEach((file, index) => {
      formDataToSend.append(`documents[${index}]`, file); 
    });

    formDataToSend.append('logo', formData.logo);

    try {
      const result = await ApiService.applyMerchant(formDataToSend); 
      if (result.status === 200 || result.status === 201) {
        setSuccessMessage('Application submitted successfully!');
        setShow(true);
        setFormData({
          shop_name: '',
          registration_number: '',
          trading_year: '',
          owner_name: '',
          address: '',
          website: '',
          documents: [], 
          logo: null, 
        });
      } else {
        setSubmitError('Application failed. Please try again.');
      }
    } catch (err) {
      if (err.status === 422) {
        setErrors(err.errors);
      } else {
        setSubmitError(err.message || 'An unexpected error occurred.');
      }
    }
  };

  return (
    <>
      <div className="apply-merchant">
        <div className="container">
          <div className="apply-inner">
            <div className="heading">
              <h3>Apply for Merchants</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="input-group">
                    <label htmlFor="shop_name">Shop Name</label>
                    <input type="text" name="shop_name" id="shop_name" placeholder="Enter shop name" value={formData.shop_name} onChange={handleChange} />
                    {errors.shop_name && <span className="error">{errors.shop_name}</span>}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="input-group">
                    <label htmlFor="registration_number">Registration Number</label>
                    <input type="text" name="registration_number" id="registration_number" placeholder="Enter registration number" value={formData.registration_number} onChange={handleChange} />
                    {errors.registration_number && <span className="error">{errors.registration_number}</span>}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="input-group">
                    <label htmlFor="owner_name">Owner Name</label>
                    <input type="text" name="owner_name" id="owner_name" placeholder="Enter owner name" value={formData.owner_name} onChange={handleChange} />
                    {errors.owner_name && <span className="error">{errors.owner_name}</span>}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="input-group">
                    <label htmlFor="address">Address</label>
                    <input type="text" name="address" id="address" placeholder="Enter address" value={formData.address} onChange={handleChange} />
                    {errors.address && <span className="error">{errors.address}</span>}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="input-group">
                    <label htmlFor="website">Website</label>
                    <input type="text" name="website" id="website" placeholder="Enter website" value={formData.website} onChange={handleChange} />
                    {errors.website && <span className="error">{errors.website}</span>}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="input-group">
                    <label htmlFor="trading_year">Trading Year</label>
                    <input type="number" name="trading_year" id="trading_year" placeholder="Enter trading year" value={formData.trading_year} onChange={handleChange} />
                    {errors.trading_year && <span className="error">{errors.trading_year}</span>}
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="input-group">
                    <label htmlFor="documents">Upload Documents (PDF/Image)</label>
                    <input type="file" name="documents" id="documents" accept=".pdf,.jpg,.jpeg,.png" multiple onChange={handleDocumentChange} />
                    {errors.documents && <span className="error">{errors.documents}</span>}
                  </div>
                </div>

                {/* <div className="col-12 col-md-6">
                  <div className="input-group">
                    <label htmlFor="logo">Upload Logo</label>
                    <input type="file" name="logo" id="logo" accept=".jpg,.jpeg,.png" onChange={handleLogoChange} />
                    {errors.logo && <span className="error">{errors.logo}</span>}
                  </div>
                </div> */}

                <div className="col-12">
                  <div className="signup-button">
                    <Link to="" className="button" onClick={handleSubmit}>
                      Apply now
                    </Link>
                  </div>
                </div>

                {submitError && <span className="error">{submitError}</span>}
                {successMessage && <span className="success">{successMessage}</span>}
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <ApprovalPandding close={() => setShow(false)} />
      </Modal>
    </>
  );
};

export default ApplyMerchant;
