import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import ApiService from '../../../services/Api'; 
import Header from '../../Header';
import PageBanner from '../../PageBanner'; 
import { Link } from 'react-router-dom';
import Loader from '../../Loader';

const SubCategoryPage = () => {
    const { id, slug } = useParams(); 
    const [subcategories, setSubcategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); 

    const defaultImage = '/assets/Image/no-image-icon-23485.png'; // Fallback image path

    useEffect(() => {
        const fetchSubCategories = async () => {
            try {
                const result = await ApiService.getSubCategories(id); 
                if (result.status === 200 || result.status === 201) {
                    setSubcategories(result.data);
                } else {
                    console.error("Failed to fetch subcategories");
                }
            } catch (err) {
                console.error("Error fetching subcategories:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchSubCategories();
    }, [id]);

    const handleCardClick = (subcategory) => { 
        const encodedName = encodeURIComponent(subcategory.slug);
     
        navigate(`/subcategory/${id}/${subcategory.id}`);  
    };

    return ( 
        <>
            <Header />
            <PageBanner title='Sub Category lists' desc={`( ${slug} )`} /> 
            
            {loading ? (
                <Loader />
            ) : (
                <div className="cupon-outer">
                    <div className="container">
                        <div className="cupon-inner row">
                            {subcategories.map((subCat) => {
                                const imageUrl = subCat.image_url || defaultImage;

                                return (
                                    <div className="item" key={subCat.id}>
                                        <div className="item-inner" onClick={() => handleCardClick(subCat)}>
                                            <div className="img-content">
                                                <img 
                                                    src={imageUrl} 
                                                    alt={subCat.name} 
                                                    className="subcategory-image" 
                                                    onError={(e) => { e.target.onerror = null; e.target.src = defaultImage; }} 
                                                />
                                            </div>
                                            <div className="text-content">
                                                <p>{subCat.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SubCategoryPage;
