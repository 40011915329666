import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaX } from 'react-icons/fa6';
import { Modal } from 'react-bootstrap';
import ApiService from '../../services/Api';

const ReferralPopup = ({ isOpen, onClose }) => {
    const [referralId, setReferralId] = useState('');
    const [error, setError] = useState('');

    if (!isOpen) return null;

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission

        // Clear existing error messages
        setError('');

        // Validate the referral ID
        if (!referralId.trim()) {
            setError('Please enter a referral ID.');
            return;
        }

        try {
            const result = await ApiService.referedPerson({ referral_id: referralId });
            if (result.status === 200 || result.status === 201) {
                // Handle successful verification (e.g., open a user coupon modal)
                onClose(); // Close the popup or perform other actions
            } else {
                setError('Invalid referral ID. Please try again.');
            }
        } catch (error) {
            console.log(error);
            if (error.status === 422) {
                setError(error.errors);
            } else if (error.status === 400) {
                setError(error.errors);
            } else {
                setError('An error occurred. Please try again.');
            }
        }
    };

    return (
        <Modal id="myModal" show={isOpen}>
            <div className="login-outer referral-modal-outer">
                <div className="login-inner row">
                    <div className="col-12 col-sm-6 left">
                        <div className="img-content">
                            <img src="/assets/Image/refferalId.png" alt="" />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 right">
                        <button onClick={onClose}><FaX /></button>
                        <div className="inner_reff">
                            <div className="heading">
                                <h3>Referral ID</h3>
                                <p>Did you have a referral ID? Enter it here to get offers for this app.</p>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="inputgroup">
                                    <input
                                        type="text"
                                        name="refid"
                                        id="refid"
                                        placeholder="Enter Referral ID"
                                        className="custom-input"
                                        value={referralId}
                                        onChange={(e) => setReferralId(e.target.value)}
                                    />
                                </div>
                                <div className="submit-button">
                                    <Link to='' className='button'  onClick={handleSubmit}>Submit</Link>
                                </div>
                                {error && <div className="error mt-3">{error}</div>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ReferralPopup;
