import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../../services/Api'; // Import your API service
// import UsercuponModal from './UsercuponModal';
const CuponCodeModal = ({  userData, onClose, onOpenUserCupon }) => {
console.log(userData);
  const [couponCode, setCouponCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');


  const handleCouponSubmit = async () => {
    if (!couponCode) {
      setErrorMessage('Please enter a coupon code');
      return;
    }
    const coupon_code = couponCode;
    // id
    const verifydata = {
      coupon_code:coupon_code,
      user_id:userData.id
    }
    console.log(userData);
    try {
      const result = await ApiService.verifyCouponCode(verifydata);
      if (result.status === 200 || result.status === 201) {
       
        setErrorMessage('');
        setSuccessMessage('Coupon code is valid');
        localStorage.removeItem('couponDetails');
       localStorage.setItem('couponDetails', JSON.stringify(result.data));
        onOpenUserCupon(); 
      } else {
        setErrorMessage('');
        setErrorMessage('Coupon code is invalid or expired');
      }
    } catch (error) {
   
      setErrorMessage('');
      setErrorMessage(error.errors);
    }
  };

  return (
    <>
      <div className="enter-cupon-code">
        <div className="enter-inner">
          <div className="user-info">
            <div className="img-content">
              
              <img src={userData ? userData.image_url : '/assets/Image/user6.jpeg'} alt="User" />
            </div>
            <div className="text-content">
              <div className="user-name">{userData ? userData.name : 'User Name'}</div>
              <div className="camdell-user-msg">{userData.name} is a Camdell user</div>
            </div>
          </div>

          <div className="cupon-code-info">
            <div className="heading">
              <h3>Enter offline coupon code </h3>
            </div>
            <input
              type="text"
              placeholder="Enter coupon code"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
          </div>
          

          <div className="submit-button">
            <Link className="button" onClick={handleCouponSubmit}>
              Submit
            </Link>
          </div>
          <div className="mt-3">
            {errorMessage && <div className="error">{errorMessage}</div>}
            {successMessage && <div className="success">{successMessage}</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default CuponCodeModal;
