import React from 'react'
import MearchantHeader from './MearchantHeader'
import PageBanner from '../PageBanner'
import Editdealform from './AddNewDeals/EditDeal'

const CouponEdit = () => {
  return ( 
    <>
    <MearchantHeader/>
      <PageBanner title='Merchant'/>
      <Editdealform/>
    </> 
  )
}

export default CouponEdit;
