import React from 'react'
import OperatorHeader from './OperatorHeader'
import OperatorDescription from '../Operator/CuponDescription/OperatorDescription'
import PageBanner from '../PageBanner'

const OperatorCuponDescriptionPage = () => {
  return (
    <>
    <OperatorHeader/>
        <PageBanner title='coupons description'/>
        <OperatorDescription/>
    </>
  )
}

export default OperatorCuponDescriptionPage
