import React from 'react';
import { useParams } from 'react-router-dom'; 
import PageBanner from '../PageBanner';
import MearchantHeader from './MearchantHeader';
import MerchntStatic from './Statics/MerchntStatic';

const MearchantStaticsPage = () => {
  const { id } = useParams();

  return (
    <>
      <MearchantHeader />
      <PageBanner title="Statistics" />
      <MerchntStatic />
    </>
  );
}

export default MearchantStaticsPage;
