import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import GetCuponCode from './GetCuponCode';
import ApiService from '../../services/Api';
import { Spin } from 'antd'; 
import Loader from '../Loader';

const PymentDetailsone = () => {
    const { planId } = useParams();
    const [show, setShow] = useState(false);
    const [count, setCount] = useState(0);
    const [selectedOption, setSelectedOption] = useState('option1');
    const [promoCode, setPromoCode] = useState('');
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [error, setError] = useState(null);
    const [submitError, setSubmitError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [useWallet, setUseWallet] = useState(false);
    const [finalAmount, setFinalAmount] = useState(null);
    const [promoValid, setPromoValid] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchPaymentDetails = async () => {
            if (!planId) {
                setError('Invalid Plan ID');
                setLoading(false);
                return;
            }

            try {
                const result = await ApiService.getPlanDetails(planId);
                if (result.status === 200 || result.status === 201) {
                    setPaymentDetails(result.data);
                    setFinalAmount(result.data.price);
                    setCount(result.data.loyalty_points);
                } else {
                    setError('Failed to load plans.');
                }
            } catch (err) {
                setError('An error occurred while fetching plans.');
            } finally {
                setLoading(false);
            }
        };

        fetchPaymentDetails();
    }, [planId]);

    const handleshow = () => {
        setShow(true);
    };

    const hadleClose = () => {
        setShow(false);
    };


    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        if (event.target.value === 'option1') {
            setPromoCode('');
            setPromoValid(null);
            setFinalAmount(paymentDetails.price); 
        } else if (event.target.value === 'option2') {
            setCount(paymentDetails.loyalty_points);
            setFinalAmount(paymentDetails.price - count); 
        }
    };

    const handleIncrement = () => {
        if (count < paymentDetails.loyalty_points) {
            const newCount = count + 1;
            setCount(newCount);
            setFinalAmount(paymentDetails.price - newCount); 
        }
    };

    const handleDecrement = () => {
        if (count > 0) {
            const newCount = count - 1;
            setCount(newCount);
            setFinalAmount(paymentDetails.price - newCount); 
        }
    };

    const toggleUseWallet = () => {
        const walletAmount = parseFloat(paymentDetails.wallet_balance) || 0;

        if (walletAmount > 0) {
            if (!useWallet) {
                setFinalAmount(parseFloat(paymentDetails.price) - walletAmount);
                setPromoCode('');
            } else {
                setFinalAmount(parseFloat(paymentDetails.price));
            }
            setUseWallet(!useWallet);
        } else {
            alert('Wallet balance must be greater than 0 to use it.');
        }
    };

    const handlePromoCodeVerify = async () => {
        if (!paymentDetails) return;
        setPromoValid(null);
        setFinalAmount(null);
        if (!promoCode) {
            setSubmitError('Please enter a promo code.');
            return;
        }

        let formData = {
            code: promoCode,
            amount: paymentDetails.price,
        };

        try {
            const result = await ApiService.verifyPromoCode(formData);
            if (result.status === 200) {
                setSubmitError('');
                setPromoValid(true);
                setFinalAmount(result.discountedPrice);
            } else {
                setPromoValid(false);
                setSubmitError('Invalid promo code.');
            }
        } catch (error) {
            if (error.status === 422) {
                setSubmitError(error.errors);
            }
            setPromoValid(false);
        }
    };

    // useEffect(() => {
    //     if (promoCode) {
    //         handlePromoCodeVerify();
    //     }
    //     else{
    //         setSubmitError('') ;
    //         setPromoValid(null);
    //         setFinalAmount(paymentDetails.price);
    //     }
    // }, [promoCode]);

    useEffect(() => {
        if (promoCode && paymentDetails) {
            handlePromoCodeVerify();
        } else {
            setSubmitError('');
            setPromoValid(null);
            if (paymentDetails) {
                setFinalAmount(paymentDetails.price);
            }
        }
    }, [promoCode, paymentDetails]);

    const handlePaymentProcess = async () => {
        let paymentData = {
            planId,
            finalAmount,
            useWallet,
        };

        if (useWallet) {
            paymentData.walletamount = paymentDetails.wallet_balance;
        }

        if (!useWallet && selectedOption === 'option2' && count > 0) {
            paymentData.loyaltyPoints = count;
        }

        if (!useWallet && selectedOption === 'option1' && promoCode && promoValid) {
            paymentData.promoCode = promoCode;
        }

        try {   
            const result = await ApiService.processPayment(paymentData);
            if (result.status === 200 || result.status === 201) {
                setSubmitError('');
                setPromoCode('');
                setPromoValid(null);
                setSuccessMessage('Payment successfully completed');
              setTimeout(() => {
                const  id  = localStorage.getItem('couponId');
                if (id) {
                    navigate(`/cupondescription/${id}`);  
                    
                } else {
                    navigate(`/`); 
                }
                
            }, 1000);
              
            }
        } catch (error) {
            if (error.status === 422) {
                setSubmitError('Promo code is wrong');
            }
        }
    };

    if (loading) {
        
        return <Loader />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <>
            <div className="pyment-detail-outer">
                <div className="container">
                    <div className="pyment-detail-inner">
                        <div className="row">
                            <div className="col-md-6 col-12 item">
                                <div className="item-inner">
                                    <div className="wallet-amount">
                                        <p>Wallet amount</p>
                                        <div className="amount">
                                            <span>
                                                <span className='ruppes-icon'>₹ </span>
                                                {paymentDetails.wallet_balance}
                                            </span>
                                            {paymentDetails.wallet_balance > 0 && (
                                                <Link to="#" onClick={toggleUseWallet}>
                                                    {useWallet ? 'Remove Wallet' : 'Use Now'}
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                    <div className="payment-details">
                                        <h3>Payment Details :</h3>
                                        {paymentDetails && (
                                            <>
                                                <p><span>Sub - Total amount </span> <span>: <span className='ruppes-icon'>₹ </span> {paymentDetails.price}</span></p>
                                                <p><span>Wallet amount  </span> <span>: <span className='ruppes-icon'>₹ </span> {useWallet ? paymentDetails.wallet_balance : '0'}</span></p>
                                                {!useWallet && selectedOption === 'option2' && (
                                                        <p>
                                                            <span>Loyalty Points Used </span>
                                                            <span>: <span className='ruppes-icon'>₹ </span> {count}</span>
                                                        </p>
                                                    )}
                                                    {!useWallet && selectedOption === 'option1' && promoValid && (
                                                        <p>
                                                            <span>Promo Code Discount </span>
                                                            <span>: <span className='ruppes-icon'>₹ </span> {paymentDetails.price - finalAmount}</span>
                                                        </p>
                                                    )}
                                                <div className="line"></div>
                                                <p><span>Final Amount  </span> <span>: <span className='ruppes-icon'>₹ </span> {finalAmount}</span></p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 item">
                                <div className="item-inner">
                                    <div className="premium">
                                        <div className="img-content">
                                            <img src="/assets/Image/subscriptionmodel.png" alt="" />
                                        </div>
                                        <div className="text-content">
                                            <h3>Premium</h3>
                                            <span>PER Month</span>
                                            <p className="ruppes"><span className='ruppes-icon'>₹ </span><span> {paymentDetails.price}</span></p>
                                        </div>
                                    </div>
                                    {!useWallet && (
                                        <div className="promocode">
                                            <select value={selectedOption} onChange={handleSelectChange}>
                                                <option value="option1">Enter Promo Code</option>
                                                <option value="option2">Select Loyalty point</option>
                                            </select>

                                            {selectedOption === 'option1' && (
                                                <>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Promo Code"
                                                        value={promoCode}
                                                        onChange={(e) => setPromoCode(e.target.value)} 
                                                    />
                                                    {promoValid === true && <span className="success">Promo code applied successfully.</span>}
                                                    {promoValid === false && <span className="error">Invalid promo code.</span>}
                                                </>
                                            )}

                                            {selectedOption === 'option2' && (
                                                <>
                                                    <div className="loyalty-points">
                                                        <div className="img-content">
                                                            <img src="/assets/Image/Coins.png" alt="" />
                                                        </div>
                                                        <span>Loyalty Point </span>
                                                        <div className="counter">
                                                            <button onClick={handleDecrement}>-</button>
                                                            <span className='count-number'>{count}</span>
                                                            <button onClick={handleIncrement}>+</button>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}

                                    <div className='mt-5'>
                                         <Link className="button" onClick={handlePaymentProcess}>Payment Process</Link>
                                    </div>
                                    {successMessage && <span className="success">{successMessage}</span>}
                                    {submitError && <span className="error">{submitError}</span>}
                                </div>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PymentDetailsone;
