import ShoperHeader from '../Components/Shoper/ShoperHeader'
import PageBanner from '../Components/PageBanner'
const UnauthorizedPage = () => {
    return (
        <>
        <ShoperHeader/>
        <PageBanner title='unauthorized'/>
        <div style={{ textAlign: 'center', padding: '50px' }}>
            <img 
                src="/assets/Image/unauthorized.jpg" 
                alt="Unauthorized Access" 
                style={{ maxWidth: '100%', height: '300px', objectFit: 'contain' }} 
            />
            <h1>You are not authorized to view this page.</h1>
        </div>
        </>
    );
};

export default UnauthorizedPage;
