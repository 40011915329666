import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FiPackage } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Addressing from './Addressing';
import ApiService from '../../../../services/Api'; // Assuming ApiService is set up to make API calls

const BestDeal = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showAddressingComponent, setShowAddressingComponent] = useState(false);
  const [items, setItems] = useState([]);  // State to hold the fetched items
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const [pointError, setPointError] = useState('');  // State for displaying error message

  const handleClose = () => {
    setShowModal(false);
    setSelectedItem(null);
    setPointError('');  // Reset point error when modal is closed
  };
  
  const handleShow = (item) => {
    setShowModal(true);
    setSelectedItem(item);
  };

  const handleAddressingComponent = (productId, productPrice) => {
    const points = localStorage.getItem('loyalty_points');
    const loyaltyPoints = points ? Number(points) : 0;

    // Check if the loyalty points are sufficient
    if (loyaltyPoints < productPrice) {
      setPointError('You do not have enough points to redeem this product.');
      return; // Stop further execution if points are not enough
    }

    // Set the product ID and proceed to Addressing Component
    localStorage.setItem('productId', productId);
    setShowModal(false);
    setShowAddressingComponent(true);
  };

  // Fetch the best deals from the API
  useEffect(() => {
    const points = localStorage.getItem('loyalty_points');
    if (points) {
        setLoyaltyPoints(Math.round(Number(points)));
      }
    const fetchBestDeals = async () => {
      setLoading(true);
      try {
        const result = await ApiService.getProducts(); // Assuming the API endpoint
        setItems(result.data);  // Assuming the response data has the list of items
      } catch (err) {
        setError('Failed to load best deals.');
      } finally {
        setLoading(false);
      }
    };

    fetchBestDeals();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      {showAddressingComponent ? (
        <Addressing />
      ) : (
        <div className="loyalty-points-outer">
          <div className="container">
            <div className="loyalty-points-inner">
              <div className="top">
                <div className="current-coins">
                  <div className="text-content">
                    <h3>Loyalty point</h3>
                    <p>Current Points</p>
                    <div className="coins-ruppes">
                        <div className="coin-img">
                        <img src="assets/Image/Coins.png" alt="" />
                        </div>
                        <div className="coins-point">
                        {loyaltyPoints}
                        </div>
                     </div>
                  </div>
                  <div className="img-content">
                    <img src="assets/Image/loyaltypoint.png" alt="" />
                  </div>
                </div>

                <div className="oreder-now">
                  <Link to="" onClick={props.setShowOrderList}>
                    <span className="icon">
                      <FiPackage />
                    </span>{" "}
                    Your Order
                  </Link>
                </div>
              </div>
              <div className="bottom">
                <div className="heading">
                  <h3>Best Deals</h3>
                </div>
                <div className="bestdeal-outer">
                  {items.map((item, index) => (
                    <div className="item" key={index}>
                      <div className="item-inner">
                        <div className="img-content">
                          <img src={item.image_url ? item.image_url : '/assets/Image/no-image-icon-23485.png'} alt={item.name} /> 
                        </div>
                        <div className="text-content">
                          <h4>{item.name}</h4>
                          <Link to="" onClick={() => handleShow(item)}>
                            Use <img src="assets/Image/Coins.png" alt="" /> {item.price}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal with Error Handling */}
      {selectedItem && (
        <Modal show={showModal} onHide={handleClose}>
          <div className="loyalty-point-modal">
            <div className="loyalty-point-modal-inner">
              <div className="img-text">
                <div className="img-content">
                  <img src={selectedItem.image_url ? selectedItem.image_url : '/assets/Image/no-image-icon-23485.png'} alt={selectedItem.name} /> 
                </div>
                <div className="text-content">
                  <p>{selectedItem.name}</p>
                </div>
              </div>
              <div className="description"> 
                <h5>Description</h5>
                <p>This is part of a redesign for a bank account opening solution. This project had some really interesting constraints.</p>
              </div>
              
              {/* Show error message if points are insufficient */}
              {pointError && <div className="error-message" style={{ color: 'red', marginBottom: '15px' }}>{pointError}</div>}

              <div className="modal-button">
                <Link to="" onClick={() => handleAddressingComponent(selectedItem.id, selectedItem.price)} className="button">
                  Use <img src="assets/Image/Coins.png" alt="" /> {selectedItem.price}
                </Link>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default BestDeal;
