import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { FaX } from 'react-icons/fa6';
import ApiService from '../../../../services/Api';  // Update the import path as needed

const Orderlist = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [orderItems, setOrderItems] = useState([]);  // State for holding dynamic orders
  const [loading, setLoading] = useState(true);  // State for loading status
  const [error, setError] = useState(null);  // State for error handling

  const handleClose = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleShow = (item) => {
    setShowModal(true);
    setSelectedItem(item);
  };

  // Fetch order data from the API
  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        setLoading(true); // Set loading state to true before making the API call
        const result = await ApiService.getOrderList(); // Assuming ApiService.getOrderList fetches the orders
        if (result.status === 200 || result.status === 201) {
          setOrderItems(result.data);  // Assuming the API returns an array of orders in `result.data`
        } else {
          setError('Failed to fetch orders');
        }
      } catch (err) {
        setError(err.message || 'Failed to fetch orders');
      } finally {
        setLoading(false);  // Set loading state to false after the API call is completed
      }
    };

    fetchOrderData();
  }, []);

  // Handle loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Handle error state
  if (error) {
    return <div>Error: {error}</div>;
  }
  const formatDate = (dateString) => {
    // Check if the dateString is valid
    const date = new Date(dateString);
  
    if (isNaN(date)) {
      return 'N/A'; // Return 'N/A' if the date is invalid
    }
  
    const day = date.getDate();
    const suffix = (day === 1 || day === 21 || day === 31) ? 'st' :
                  (day === 2 || day === 22) ? 'nd' :
                  (day === 3 || day === 23) ? 'rd' : 'th';
  
    // Get the short month (e.g., Jan, Feb, etc.)
    const options = { month: 'short' };
    const month = new Intl.DateTimeFormat('en-US', options).format(date);
  
    // Get the last two digits of the year
    const year = date.getFullYear().toString().slice(-2);
  
    // Return the formatted date string
    return `${day}${suffix} ${month} ${year}`;
  };
  

  return (
    <>
      <div className="order-list-outer">
        <div className="container">
          <div className="order-list-inner">
            <div className="heading">
              <h3>Your Orders</h3>
            </div>

            <div className="order-list-items row">
              {
                orderItems.length > 0 ? (
                  orderItems.map((i) => {
                    return (
                      <div className="item col-12 col-xl-3 col-lg-4 col-md-6" key={i.order_id}>
                        <div className="item-inner">
                          <div className="order-id-track">
                            <div className="order-id">
                              Order ID : {i.order_id}
                            </div>
                            <Link to="#" onClick={() => handleShow(i)}>Track</Link>
                          </div>

                          <div className="content">
                            <div className="img-content">
                              <img src={i.image_url ? i.image_url : '/assets/Image/no-image-icon-23485.png'} 
                                                            alt={i.product_name} 
                                                        />
                            </div>
                            <div className="text-content">
                              <h2>{i.product_name}</h2>
                              <p>Use <img src="assets/Image/Coins.png" alt="coins" /> {i.product_price}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>No orders found</div>
                )
              }
            </div>
          </div>
        </div>
      </div>

      {/* Modal for tracking order */}
      {selectedItem && (
        <Modal show={showModal} onHide={handleClose}>
          <div className="trackmodal-outer">
            <div className="trackmodal-inner">
              <div className="item">
                <div className="item-inner">
                  <div className="order-id-track">
                    <div className="order-id">
                      Order ID : {selectedItem.order_id}
                    </div>
                    <Link to="#" onClick={handleClose}><FaX /></Link>
                  </div>

                  <div className="content">
                    <div className="img-content">
                    <img src={selectedItem.image_url ? selectedItem.image_url : '/assets/Image/no-image-icon-23485.png'} 
                                                            alt={selectedItem.product_name} />
                    </div>
                    <div className="text-content">
                      <h2>{selectedItem.product_name}</h2>
                      <p>Use <img src="assets/Image/Coins.png" alt="coins" /> {selectedItem.product_price}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="product-tracker">
              <ul>
                {selectedItem.status === 'rejected' ? (
                    <li>
                    <span>
                        <b>Order Status:</b> Rejected
                    </span>
                    </li>
                ) : (
                    <>
                   <li>
                    <span>
                        <b>Order created on</b> {selectedItem.created_on ? formatDate(selectedItem.created_on) : 'N/A'}
                    </span>
                    </li>
                    <li>
                    <span>
                        <b>Order confirmed on</b> {selectedItem.confirmed_on ? formatDate(selectedItem.confirmed_on) : 'N/A'}
                    </span>
                    </li>
                    <li>
                    <span>
                        <b>Delivery on</b> {selectedItem.delivered_on ? formatDate(selectedItem.delivered_on) : 'N/A'}
                    </span>
                    </li>
                    </>
                )}
                </ul>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Orderlist;
