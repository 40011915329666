import React from 'react'
import MearchantHeader from '../Merchant/MearchantHeader'
import MearchntPayment from '../Merchant/Subscription/MearchntPayment'
import PageBanner from '../PageBanner'

const MearchntPaymentPage = () => {
  return (
    <>
        <MearchantHeader/>
        <PageBanner title='Payment Details' />
        <MearchntPayment/>
    </>
  )
}

export default MearchntPaymentPage
