import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../../../services/Api';

const Balance = () => {
    const [balance, setBalance] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch balance data from the API
    useEffect(() => {
        const fetchBalanceData = async () => {
            try {
                setLoading(true); // Set loading state to true before making the API call
                const result = await ApiService.getBalance(); // Assuming ApiService.getBalance fetches the balance

                if (result.status === 200 || result.status === 201) {
                    const walletBalance = result.data.wallet_balance;  // Assuming the API returns the balance in `result.data.wallet_balance`

                    // Save balance in localStorage
                    localStorage.setItem('wallet_balance', walletBalance);

                    setBalance(walletBalance); // Assuming the API returns the balance in `result.data.balance`
                } else {
                    setError('Failed to fetch balance');
                }
            } catch (err) {
                setError(err.message || 'Failed to fetch balance');
            } finally {
                setLoading(false);  // Set loading state to false after the API call is completed
            }
        };

        fetchBalanceData();
    }, []);  // Empty array means this effect runs only once when the component mounts

    // Conditional rendering based on state
    return (
        <>
            <div className="balance-outer">
                <div className="item your-balance">
                    <div className="item-inner">
                        <div className="heading">
                            <h4>Your Balance</h4>
                        </div>

                        {/* Render loading state or actual balance */}
                        {loading ? (
                            <div>Loading...</div>
                        ) : error ? (
                            <div className="error">{error}</div>
                        ) : (
                            <div className="balance-amount">
                                <span className="ruppes-icon">₹ </span>{balance}
                            </div>
                        )}

                        <div className="use-now">
                            {/* Example Link - customize according to your use case */}
                            {/* <Link to="/use-balance">Use Now</Link> */}
                        </div>
                        
                    </div>
                    
                </div>
                {/* <div className="item total-income">
                    <div className="item-inner">
                        <div className="heading">
                            <h4>Total income</h4>
                        </div>
                        <div className="income-amount"><span className='ruppes-icon'>₹ </span>400</div>
                    </div>
                </div>
                <div className="item total-expenses">
                    <div className="item-inner">
                        <div className="heading">
                            <h4>Total expenses</h4>
                        </div>
                        <div className="expenses-amount"><span className='ruppes-icon'>₹ </span>400</div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default Balance;
