import React from 'react'
import { Link } from 'react-router-dom'

const ApprovalPandding = ({close}) => {
    return (
        <>
            <div className="approval-outer">
                <div className="approval-inner">
                    <div className="heading">
                        <h3>
                        Approval pending
                        </h3>
                    </div>
                    <p>Once Approved you can access the Merchant features</p>
                    <Link to={'/'} className="button" onClick={close}>Back</Link>
                </div>
            </div>
        </>
    )
}

export default ApprovalPandding
