import React, { useState, useEffect } from 'react';
import ApiService from '../../../../services/Api'; // Assuming ApiService is set up to make API calls

const Connect = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Assuming the API returns an array of users with `img`, `name`, and `referralid`
        const result = await ApiService.getConnectData(); // Update with the actual API method
        setUsers(result.data);
      } catch (err) {
        setError('Failed to load users.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="connect-outer">
      <div className="container">
        <div className="connect-inner">
          {
            users.map((user, index) => {
              return (
                <div className="item" key={index}>
                  <div className="item-inner">
                    <div className="img-content">
                      <img src={user.image_url || 'assets/Image/user1.png'} alt={user.name} />
                    </div>
                    <div className="text-content">
                      <div className="user-name">
                        {user.name}
                      </div>
                      <div className="referral-id">
                        <span>Referral ID : </span>{user.referral_code}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default Connect;
