import { Tabs } from 'antd';
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { BiSolidDiscount } from 'react-icons/bi';
import { BsQrCodeScan } from 'react-icons/bs';
import { FaIdCard } from 'react-icons/fa6';
import PageBanner from '../PageBanner'
import CuponCodeEnter from './OnlineCoupons/CuponCodeEnter';
import EnterPurchasingAmount from './OnlineCoupons/EnterPurchasingAmount';
import UserCuponOnline from './OnlineCoupons/UserCuponOnline';
import Scanner from './Scanner/Scanner';
import SuccessfulModal from './Scanner/SuccessfulModal';
import ShoperHeader from './ShoperHeader'
import UsedCupon from './UsedCupon/UsedCupon';

const CashierScanner = () => {
    const [activeKey, setActiveKey] = useState('1');
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [refreshUsedCupon, setRefreshUsedCupon] = useState(0); // State to trigger refresh
    const handleClose = () => setShowModal(false);

    const handleCuponCodeEnter = () => {
        setModalContent(<CuponCodeEnter onClose={handleClose} onOpenUserCupon={handleUserCupon} />)
        setShowModal(true)
    }
//  change
    const handleUserCupon = (data) => {

        setModalContent(<UserCuponOnline couponDetails={data} onClose={handleClose} onOpenSuccessfull={handleSuccessfull}  />);
        setShowModal(true);
    };

    const handlePurchasing = () => {
        setModalContent(<EnterPurchasingAmount onClose={handleClose} onOpenSuccessfull={handleSuccessfull} />)
    }
    const handleSuccessfull = () => {
        setModalContent(<SuccessfulModal onClose={handleClose} />)
    }

    // const handleTabChange = (key) => {
    //     if (key === '3') {
    //         handleCuponCodeEnter();
    //         setActiveKey('1');
    //     } else {
    //         setActiveKey(key);
    //     }
    // }
    const handleTabChange = (key) => {
        if (key === '3') {
            handleCuponCodeEnter();
            setActiveKey('1');
        } else if (key === '2') {
            setRefreshUsedCupon(prev => prev + 1); // Trigger refresh for UsedCupon
            setActiveKey(key);
        } else {
            setActiveKey(key);
        }
    };
  return (
    <>
        <ShoperHeader/>
        <PageBanner title='Cashier'/>
        <Tabs
                className='dashbordheader'
                centered
                defaultActiveKey="1"
                activeKey={activeKey}
                onChange={handleTabChange}
                items={[
                    {
                        label: 'Scanner',
                        key: '1',
                        children: <Scanner />,
                        icon: <BsQrCodeScan />
                    },
                    {
                        label: 'Used coupons',
                        key: '2',
                        children: <UsedCupon refresh={refreshUsedCupon} />,
                        icon: <FaIdCard />

                    },
                    {
                        label: 'Online Upload used coupons',
                        key: '3',
                        children: '',
                        icon: <BiSolidDiscount />

                    },
                ]}
            />
            {showModal && (
                <Modal show={showModal} onHide={handleClose}>
                    {modalContent}
                </Modal>

            )}

    </>
  )
}

export default CashierScanner
