import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCalendarAlt } from 'react-icons/fa';
import { IoTime } from "react-icons/io5";
import ApiService from '../../../services/Api'; // Adjust the import according to your project structure

const UserCuponOnline = ({ couponDetails,onClose, onOpenSuccessfull }) => {
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    const minAmount = couponDetails.miniamount;
    const maxAmount = couponDetails.maxamount;
    const amountFloat = parseFloat(amount);

    if (!amount) {
      newErrors.amount = "Amount is required.";
    } else if (amountFloat < minAmount || amountFloat > maxAmount) {
      newErrors.amount = `Amount must be between ${minAmount} and ${maxAmount}.`;
    }
    if (!date) newErrors.date = "Date is required.";
    if (!time) newErrors.time = "Time is required.";
    return newErrors;
  };

  const handleSubmit = async () => {
    const newErrors = validate();
    setErrors(newErrors);
    
    if (Object.keys(newErrors).length === 0) {
      const verifyData = {
        amount,
        date: `${date}T${time}`,
        coupon_id: couponDetails.id, 
        purchaser_id: couponDetails.purchaser_id, 
        unique_code: couponDetails.unique_code, 
        
      };

      try {
        const result = await ApiService.OnlinepurchaseAmount(verifyData);
        if (result.status === 200 || result.status === 201) {
          setMessage('Submitted successfully!');
       
          onOpenSuccessfull();
           setTimeout(() => {
            onClose();
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        // if (error.status === 404) {
        //   setErrors(error.message);
        // }
        // else if(error.status === 422){
        //     setErrors(error.message);
        // } else {
        //   setMessage('Submission failed. Please try again.');
        // }
      }
    }
  };

  return (
    <div className="user-cupon-outer">
      <div className="user-cupon-inner">
        <div className="user-info">
          <div className="img-content">
          
            <img src={couponDetails.profile_picture || '/assets/Image/user6.jpeg'} alt="" />
          </div>
          <div className="text-content">
            <div className="user-name">{couponDetails.name}</div>
          </div> 
        </div>

        <div className="cupon-row">
          <div className="item">
            <div className="item-inner">
              <div className="top">
                <div className="img-content">
                  <img src={couponDetails.image_url || '/assets/Image/no-image-icon-23485.png'} alt="" />
                  <h2>{couponDetails.shop_name}</h2>
                </div>
              </div>
              <div className="bottom row">
                <div className="col-6">Cat: {couponDetails.category_name}</div>
                <div className="col-6">Started: {couponDetails.startdate}</div>
                <div className="col-6">Sub-Cat: {couponDetails.subcategory_name}</div>
                <div className="col-6">Ended: {couponDetails.enddate}</div>
                <div className="col-6">Min amount: {couponDetails.miniamount}</div>
                <div className="col-6">Max amount: {couponDetails.maxamount}</div>
                <div className="col-6">Deals: {couponDetails.ctype}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="purchasing-amount">
          <div className="inputgroup">
            <label htmlFor="">Enter Purchasing amount</label>
            <input
              type="number"
              placeholder='Enter amount'
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          
          </div>
          {errors.amount && <span className="error">{errors.amount}</span>}
        </div>

        <div className='purchase-datetime-container'>
          <div className="input-group-new mb-4">
            <label htmlFor="">Enter Date & Time</label>
            <div className="row-new mt-2">
              <div className="col-half">
                <div className="input-wrapper-new date-input-new">
                  <input
                    type="date"
                    className='date-time-input-new'
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                  <span className="icon-new">
                    <FaCalendarAlt />
                  </span>
               
                </div>
                {errors.date && <span className="error">{errors.date}</span>}
              </div>
              
              <div className="col-half">
                <div className="input-wrapper-new">
                  <input
                    type="time"
                    className='date-time-input-new'
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                  />
                  <span className='icon-new'>
                    <IoTime />
                  </span>
                 
                </div>
                {errors.time && <span className="error">{errors.time}</span>}
              </div>
            </div>
          </div>
        </div>

        <div className="submit-button">
          <Link className='button' onClick={handleSubmit}>Submit</Link>
        </div>
      </div>
        {message && <div className=" mt-3 success message">{message}</div>}
    </div>
  );
};

export default UserCuponOnline;
