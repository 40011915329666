import React, { useState } from 'react';
import { FaX } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import ApiService from '../../services/Api';

const Signup = ({ onClose, onOpenMemberLogin }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        mobile: '',
        otp: ''
    });
    const [errors, setErrors] = useState({});
    const [submitError, setSubmitError] = useState('');
    const [isAgreed, setIsAgreed] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
        setSubmitError('');
    };

    const handleCheckboxChange = () => {
        setIsAgreed((prev) => !prev);
        setErrors((prevErrors) => ({ ...prevErrors, terms: '' }));
    };

    const validateForm = () => {
        const newErrors = {};
        const MAX_PASSWORD_LENGTH = 12;
        
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';

        if (!formData.password) {
            newErrors.password = 'Password is required';
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,12}$/.test(formData.password)) {
            newErrors.password = `Password must be between 8 and ${MAX_PASSWORD_LENGTH} characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.`;
        }

        if (!formData.mobile) {
            newErrors.mobile = 'Mobile number is required';
        } else if (!/^\d{10}$/.test(formData.mobile)) {
            newErrors.mobile = 'Mobile number must be 10 digits';
        }
        if (!formData.otp) newErrors.otp = 'OTP is required';
        if (!isAgreed) newErrors.terms = 'You must agree to the terms of use and privacy policy';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const result = await ApiService.register(formData);
            // if (result.status === 200 || result.status === 201) {
            //     setSuccessMessage('Registration successful! Redirecting to login...');
            //     setTimeout(() => {
            //         onOpenMemberLogin();
            //     }, 2000);
            // } 
            if (result.status === 200 || result.status === 201) {
                localStorage.removeItem('token');
                localStorage.removeItem('UserData');
                localStorage.removeItem('role');
                localStorage.removeItem('is_approved');
                localStorage.removeItem('sub_manager');
                localStorage.removeItem('loyalty_points');
                localStorage.removeItem('profile_pic');
                localStorage.removeItem('location');
                localStorage.removeItem('couponDetails');
                localStorage.removeItem('couponId');
                localStorage.removeItem('productId');
                localStorage.removeItem('wallet_balance');
            
                localStorage.setItem('UserData', JSON.stringify(result)); 
                localStorage.setItem('showreff',true);
                localStorage.setItem('role', result.role);
                localStorage.setItem('loyalty_points', result.success.loyalty_points);
                localStorage.setItem('token', result.success.token);
                localStorage.setItem('is_approved', result.success.is_approved);
            
            
                // Display success message and redirect
                setSuccessMessage('Registration successful! Redirecting to home...');
                setTimeout(() => {
                 // Function to open the login modal or page
                    // Redirect to home page
                    window.location.href = '/';
                }, 2000);
            }
            else {
                setSubmitError('Registration failed. Please try again.');
            }
        } catch (err) {
            if (err.status === 422) {
                setErrors(err.errors);
            } else {
                setSubmitError(err.message || 'An unexpected error occurred.');
            }
        }
    };

    return (
        <>
            <div className="signup-outer">
                <div className="signup-inner row">
                    <div className="col-12 col-sm-6 left">
                        <div className="img-content">
                            <img src="/assets/Image/Discount.png" alt="" />
                            <img src="/assets/Image/signupcuponbg.png" alt="" />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 right">
                        <button onClick={onClose}><FaX /></button>
                        <div className="inner">
                            <div className="heading">
                                <h3>Sign Up</h3>
                            </div>
                            <form method="post" onSubmit={handleSubmit}>
                                <input
                                    type="text"
                                    name='name'
                                    placeholder='Name'
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                {errors.name && <span className="error">{errors.name}</span>}

                                <input
                                    type="email"
                                    name='email'
                                    placeholder='Email'
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                {errors.email && <span className="error">{errors.email}</span>}

                                <input
                                    type="password"
                                    name='password'
                                    placeholder='Enter your password'
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                                {errors.password && <span className="error">{errors.password}</span>}

                                <input
                                    type="number"
                                    name='mobile'
                                    placeholder='Mobile number'
                                    value={formData.mobile}
                                    onChange={handleChange}
                                />
                                {errors.mobile && <span className="error">{errors.mobile}</span>}

                                <div className="otp-group">
                                    <input
                                        type="text"
                                        name="otp"
                                        placeholder='Enter OTP'
                                        value={formData.otp}
                                        onChange={handleChange}
                                    />
                                    <input type='button' className='button' value='Get OTP' />
                                </div>
                                {errors.otp && <span className="error">{errors.otp}</span>}

                                <div className="privacy-check">
                                    <input 
                                        type="checkbox" 
                                        checked={isAgreed}
                                        onChange={handleCheckboxChange} 
                                        required 
                                    />
                                    <label>By signing up, I agree to the terms of use and privacy policy of the app</label>
                                </div>
                                {errors.terms && <span className="error">{errors.terms}</span>}

                                <Link to='' className='button' onClick={handleSubmit}>Sign Up</Link>
                                {submitError && <span className="error">{submitError}</span>}

                                {successMessage && <span className="success">{successMessage}</span>}

                                <div className="sign-in">Already have an account? <Link to='' onClick={onOpenMemberLogin}>Sign in</Link></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Signup;
