import React from 'react'
import PageBanner from '../PageBanner'
import OperatorHeader from './OperatorHeader'
import OperatorPymentPlan from './Subscription/OperatorPymentPlan'

const OperatorPymentpage = () => {
  return (
    <>
      <OperatorHeader/>
      <PageBanner title='Payment Details'/>
      <OperatorPymentPlan/>
    </>
  )
}

export default OperatorPymentpage
