import React from 'react'
import MearchantHeader from './MearchantHeader'
import MearchntSubscriptionPlan from './Subscription/MearchntSubscriptionPlan'
import PageBanner from '../PageBanner'

const MearchntSubscriptionPage = () => {
  return (
    <>
      <MearchantHeader/>
      <PageBanner title='Subscription' desc="Merchant"    />
      <MearchntSubscriptionPlan/>
    </>
  )
}

export default MearchntSubscriptionPage
