import { BaseUrl } from '../Components/BaseURL/BaseUrl';

const ApiService = {
    register: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/user/user-register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during registration:', error);
            throw error; 
        }
    },
    forgotPassword: async (data) => {
      try {
          const response = await fetch(`${BaseUrl}/user/forgot-password`, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
          });

          if (!response.ok) {
              const errorData = await response.json();
              throw { status: response.status, errors: errorData.errors || {} }; 
          }

          return await response.json();
      } catch (error) {
          console.error('Error during registration:', error);
          throw error; 
      }
  },
    
   referedPerson: async (data) => {
    try {
        const response = await fetch(`${BaseUrl}/user/verify-referal`, {
          
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} }; 
        }

        return await response.json();
    } catch (error) {
        console.error('Error during registration:', error);
        throw error; 
    }
},
    UserLogin: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/user/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    },
    Merchantlogin: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/merchant/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    },
    Manager_cashier: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/McLogin`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during login:', error);
            throw error; 
        }
    },
    applyMerchant: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/user/applymerchant`, {
                method: "POST",
                headers: {
                    // 'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: (data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during registration:', error);
            throw error; 
        }
    },
    getUserDetails: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/user/getusercode`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during registration:', error);
            throw error; 
        }
    },
    getUserProfile: async (data) => {
      try {
          const response = await fetch(`${BaseUrl}/user/userprofile`, {
              method: "GET",
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
              },
              body: JSON.stringify(data),
          });

          if (!response.ok) {
              const errorData = await response.json();
              throw { status: response.status, errors: errorData.errors || {} }; 
          }

          return await response.json();
      } catch (error) {
          console.error('Error during registration:', error);
          throw error; 
      }
   },
   getOrderList: async (data) => {
    try {
        const response = await fetch(`${BaseUrl}/user/get/orderlist`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} }; 
        }

        return await response.json();
    } catch (error) {
        console.error('Error during registration:', error);
        throw error; 
    }
 },
 getBalance: async (data) => {
  try {
      const response = await fetch(`${BaseUrl}/user/get/walletbalance`, {
          method: "GET",
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(data),
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw { status: response.status, errors: errorData.errors || {} }; 
      }

      return await response.json();
  } catch (error) {
      console.error('Error during registration:', error);
      throw error; 
  }
},
getBankVerificationStatus: async (data) => {
  try {
      const response = await fetch(`${BaseUrl}/user/get/bankdetails`, {
          method: "GET",
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(data),
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw { status: response.status, errors: errorData.errors || {} }; 
      }

      return await response.json();
  } catch (error) {
      console.error('Error during registration:', error);
      throw error; 
  }
},
getBankAccountDetails: async (data) => {
  try {
      const response = await fetch(`${BaseUrl}/user/get/showbankdetails`, {
          method: "GET",
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(data),
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw { status: response.status, errors: errorData.errors || {} }; 
      }

      return await response.json();
  } catch (error) {
      console.error('Error during registration:', error);
      throw error; 
  }
},
submitBankAccountDetails: async (data) => {
  try {
      const response = await fetch(`${BaseUrl}/user/updatebankdetails`, {
          method: "post",
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(data),
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw { status: response.status, errors: errorData.errors || {} }; 
      }

      return await response.json();
  } catch (error) {
      console.error('Error during registration:', error);
      throw error; 
  }
},

  deleteAccount: async () => {
    try {
        const response = await fetch(`${BaseUrl}/user/deleteacount`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} }; 
        }

        const data = await response.json();
        // console.log(data);
        return data;
      
    } catch (error) {
        console.error('Error fetching coupon data:', error);
        throw error; 
    }
},

submitWalletRequest: async (data) => {
  try {
      const response = await fetch(`${BaseUrl}/user/walletrequest`, {
          method: "post",
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(data),
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw { status: response.status, errors: errorData.errors || {} }; 
      }

      return await response.json();
  } catch (error) {
      console.error('Error during registration:', error);
      throw error; 
  }
},
changePassword: async (data) => {
  try {
      const response = await fetch(`${BaseUrl}/user/changepassword`, {
          method: "post",
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(data),
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw { status: response.status, errors: errorData.errors || {} }; 
      }

      return await response.json();
  } catch (error) {
      console.error('Error during registration:', error);
      throw error; 
  }
},

    getMerchantPoint: async (data) => {
      try {
            const response = await fetch(`${BaseUrl}/user/getmerchantpoint`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during registration:', error);
            throw error; 
        }
    },
    
    updateUserProfile: async (data) => {
      try {
          const response = await fetch(`${BaseUrl}/user/updateprofile`, {
            
              method: "POST",
              headers: {
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
              },
              body: (data),
          });

          if (!response.ok) {
              const errorData = await response.json();
              throw { status: response.status, errors: errorData.errors || {} }; 
          }

          return await response.json();
      } catch (error) {
          console.error('Error during registration:', error);
          throw error; 
      }
  },
    verifyUserCode: async (data) => {
      try {
          const response = await fetch(`${BaseUrl}/user/verifyusercode`, {
            
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
              },
              body: JSON.stringify(data),
          });

          if (!response.ok) {
              const errorData = await response.json();
              throw { status: response.status, errors: errorData.errors || {} }; 
          }

          return await response.json();
      } catch (error) {
          console.error('Error during registration:', error);
          throw error; 
      }
  },
    
    applycoupon: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/user/applycoupon`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during registration:', error);
            throw error; 
        }
    },
    operatorLogin: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/user/operatorLogin`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during registration:', error);
            throw error; 
        }
    },
    getDraftCouponData: async () => {
        try {
            const response = await fetch(`${BaseUrl}/user/draftcoupons`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }
    
            const data = await response.json();
            // console.log(data);
            return data;
          
        } catch (error) {
            console.error('Error fetching coupon data:', error);
            throw error; 
        }
    },
    getCouponData: async () => {
        try {
          const response = await fetch(`${BaseUrl}/user/couponsdata`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      CirculerData: async (id) => {
        try {
          const response = await fetch(`${BaseUrl}/user/coupon-progress/${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      ChartData: async (id) => {
        try {
          const response = await fetch(`${BaseUrl}/user/coupons/statistics/${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      
      getCashBackItems: async () => {
        try {
          const response = await fetch(`${BaseUrl}/user/get/topcashbackstore`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      getTrendingCoupons: async () => {
        try {
          const response = await fetch(`${BaseUrl}/user/get/trending`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      getPopularStore: async () => {
        try {
          const response = await fetch(`${BaseUrl}/user/get/populurstore`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      
      getCouponById: async (id) => {
        try {
          const response = await fetch(`${BaseUrl}/user/coupons/${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error(`Error fetching coupon with ID ${id}:`, error);
          throw error;
        }
      },
   
    category: async () => {
      try {
          const response = await fetch(`${BaseUrl}/user/category`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
              },
          });
  
          if (!response.ok) {
              const errorData = await response.json();
              throw { status: response.status, errors: errorData.errors || {} };
          }
  
          const data = await response.json();
          return data;
  
      } catch (error) {
          console.error('Error fetching category data:', error);
          throw error;
      }
  },
  
  getSubCategories: async (categoryId) => {
      try {
          const response = await fetch(`${BaseUrl}/user/subcategory/${categoryId}`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
              },
          });
  
          if (!response.ok) {
              const errorData = await response.json();
              throw { status: response.status, errors: errorData.errors || {} };
          }
  
          const data = await response.json();
          return data;
  
      } catch (error) {
          console.error('Error fetching subcategory data:', error);
          throw error;
      }
  },
  getSliderImages: async () => {
    try {
        const response = await fetch(`${BaseUrl}/user/sliderimage`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error fetching category data:', error);
        throw error;
    }
},
getExclusiveItems: async () => {
  try {
      const response = await fetch(`${BaseUrl}/user/getexclusives`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw { status: response.status, errors: errorData.errors || {} };
      }

      const data = await response.json();
      return data;

  } catch (error) {
      console.error('Error fetching category data:', error);
      throw error;
  }
},

      getCoupons: async (catid, subid) => {
        try {
        const response = await fetch(`${BaseUrl}/user/getcoupon/${catid}/${subid}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      getCoupontypeData: async (type) => {
        try {
        const response = await fetch(`${BaseUrl}/user/get/coupontype/${type}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      getwishlisttypeData: async (type) => {
        try {
        const response = await fetch(`${BaseUrl}/user/get/wishlist/${type}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      
      PurchasedCoupontype: async (ctype, ptype) => {
        try {
        const response = await fetch(`${BaseUrl}/user/purchased/${ctype}/${ptype}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      getUsedCouponData: async () => {
        try {
        const response = await fetch(`${BaseUrl}/user/get/usedcoupons`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      
      getOperatorsData: async () => {
        try {
        const response = await fetch(`${BaseUrl}/user/getoperators`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      getadsDetailcoupon: async (couponid) => {
        try {
        const response = await fetch(`${BaseUrl}/user/adscupondescription/${couponid}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      getDetailcoupon: async (couponid) => {
        try {
        const response = await fetch(`${BaseUrl}/user/cupondescription/${couponid}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      getUsedcoupon: async (couponid, type) => {
        try {
        const response = await fetch(`${BaseUrl}/user/cupondescription/${couponid}/${type}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      deleteOperator: async (id) => {
        try {
          const response = await fetch(`${BaseUrl}/user/operator/delete/${id}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
              },
            });
      
            if (!response.ok) {
              const errorData = await response.json();
              throw { status: response.status, errors: errorData.errors || {} };
            }
      
            const data = await response.json();
            return data;
      
          } catch (error) {
            console.error('Error fetching coupon data:', error);
            throw error;
          }
      },
      getIncomeData: async () => {
        try {
        const response = await fetch(`${BaseUrl}/user/getincome`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      getConnectData: async () => {
        try {
        const response = await fetch(`${BaseUrl}/user/getconnect`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      getProducts: async () => {
        try {
        const response = await fetch(`${BaseUrl}/user/getproducts`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      
      
      getMerchantIncome: async () => {
        try {
        const response = await fetch(`${BaseUrl}/user/getmerchantincome`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      downloadExcel: async (couponId, type) => {
        try {
            const response = await fetch(`${BaseUrl}/user/coupons/download/${couponId}/${type}`, {
                method: 'GET',
                headers: {
                    
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} };
            }

            const blob = await response.blob();
            return blob; // Return the Blob for the Excel file

        } catch (error) {
            console.error('Error downloading Excel:', error);
            throw error; // Re-throw the error for handling in the component
        }
    },
      getCouponCode: async (couponid) => {
        try {
        const response = await fetch(`${BaseUrl}/user/get/couponcode/${couponid}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      getOperatorProfileById: async (id) => {
        try {
        const response = await fetch(`${BaseUrl}/user/get/operator/${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      
      updateOperatorProfile: async (id, data) => {
        try {
            const response = await fetch(`${BaseUrl}/user/update/operatorprofile/${id}`, {
              
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: (data),
            });
  
            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }
  
            return await response.json();
        } catch (error) {
            console.error('Error during registration:', error);
            throw error; 
        }
    },
      checkSubscription: async () => {
        try {
        const response = await fetch(`${BaseUrl}/user/get/subscription`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },
      getSubscriptionPlans: async () => {
        try {
        const response = await fetch(`${BaseUrl}/user/get/SubscriptionPlans`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },

      getPlanDetails: async (planId) => {
        try {
        const response = await fetch(`${BaseUrl}/user/get/plan/${planId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
    
          if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} };
          }
    
          const data = await response.json();
          return data;
    
        } catch (error) {
          console.error('Error fetching coupon data:', error);
          throw error;
        }
      },

      processPayment: async (data) => {
        try {
            const response = await fetch(`${BaseUrl}/user/processpayment`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; 
            }

            return await response.json();
        } catch (error) {
            console.error('Error during registration:', error);
            throw error; 
        }
    },
    updateOrder: async (data) => {
      try {
          const response = await fetch(`${BaseUrl}/user/updateorder`, {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
              },
              body: JSON.stringify(data),
          });

          if (!response.ok) {
              const errorData = await response.json();
              throw { status: response.status, errors: errorData.errors || {} }; 
          }

          return await response.json();
      } catch (error) {
          console.error('Error during registration:', error);
          throw error; 
      }
  },
    

    verifyPromoCode: async (data) => {
      try {
          const response = await fetch(`${BaseUrl}/user/verifypromocode`, {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${localStorage.getItem('token')}`,
              },
              body: JSON.stringify(data),
          });

          if (!response.ok) {
              const errorData = await response.json();
              throw { status: response.status, errors: errorData.errors || {} }; 
          }

          return await response.json();
      } catch (error) {
          console.error('Error during registration:', error);
          throw error; 
      }
  },
  verifyCouponCode: async (data) => {
    try {
        const response = await fetch(`${BaseUrl}/user/verifycouponcode`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw { status: response.status, errors: errorData.errors || {} }; 
        }

        return await response.json();
    } catch (error) {
        console.error('Error during registration:', error);
        throw error; 
    }
},
verifyOnlineCouponCode: async (data) => {
  try {
      const response = await fetch(`${BaseUrl}/user/verifyonlinecode`, {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(data),
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw { status: response.status, errors: errorData.errors || {} }; 
      }

      return await response.json();
  } catch (error) {
      console.error('Error during registration:', error);
      throw error; 
  }
},

applyCodeForUser: async (data) => {
  try {
      const response = await fetch(`${BaseUrl}/user/applycodeforuser`, {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(data),
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw { status: response.status, errors: errorData.errors || {} }; 
      }

      return await response.json();
  } catch (error) {
      console.error('Error during registration:', error);
      throw error; 
  }
},
OnlinepurchaseAmount: async (data) => {
  try {
      const response = await fetch(`${BaseUrl}/user/onlinepurchaseamount`, {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(data),
      });

      if (!response.ok) {
          const errorData = await response.json();
          throw { status: response.status, errors: errorData.errors || {} }; 
      }

      return await response.json();
  } catch (error) {
      console.error('Error during registration:', error);
      throw error; 
  }
},

    
      
      
      
      
    
    
};

export default ApiService;
