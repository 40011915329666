import { Tabs } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BankAccount from './WalletTabs/BankAccount';
import WithDrawal from './WalletTabs/WithDrawal';
import Expenses from './WalletTabs/Expenses';
import Income from './WalletTabs/Income';
import Balance from './WalletTabs/Balance';
import MerchantExpenses from './WalletTabsMerchant/Expenses';
import MerchantIncome from './WalletTabsMerchant/Income';
import { DropdownButton, DropdownItem } from 'react-bootstrap';

const Wallet = () => {
    const [activeKey, setActiveKey] = useState('1');
    const [show, setShow] = useState(false);
    const [isApproved, setIsApproved] = useState(null);
    const [selectedView, setSelectedView] = useState('user'); // Track selected view (user or merchant)

    useEffect(() => {
        // Check `is_approved` value from localStorage
        const approvalStatus = localStorage.getItem('is_approved');
        setIsApproved(approvalStatus);
    }, []);

    const handleClose = () => {
        setShow(false);
    };

    const handleTabChange = (key) => {
        setActiveKey(key);
        if (key === '3') {
            setActiveKey('2');
            setShow(true);
        } else {
            setShow(false);
        }
    };

    const handleUserClick = () => {
        setSelectedView('user'); // Show user-specific content
        setActiveKey('1'); // Optionally, set the active tab to 'Income'
    };

    const handleMerchantClick = () => {
        setSelectedView('merchant'); // Show merchant-specific content
        setActiveKey('2'); // Optionally, set the active tab to 'Expenses'
    };

    return (
        <>
            <div className="wallet-outer">
                <div className="container">
                    <div className="wallet-inner">
                        <div className="wallet-history">
                            <div className="heading">
                                <h3>Wallet History</h3> 
                       
                                {activeKey === '1' || activeKey === '2' ? (
                                    <DropdownButton id="dropdown-basic-button" className="list-view" title="List View">
                                        {/* When "User" is clicked, show user content */}
                                        <DropdownItem as={Link} to="" onClick={handleUserClick}>
                                            User
                                        </DropdownItem>

                                        {/* Conditionally render Merchant item if is_approved is '2' */}
                                        {/* {isApproved === '2' && (
                                            <DropdownItem as={Link} to="" onClick={handleMerchantClick}>
                                                Merchant
                                            </DropdownItem>
                                        )} */}
                                    </DropdownButton>
                                ) : (
                                    <span></span>
                                )}
                            </div>

                            {/* Conditionally render content based on selectedView */}
                            <div className="wallet-tabs">
                            <Balance />
                                {selectedView === 'user' ? (
                                    <Tabs
                                        centered
                                        activeKey={activeKey}
                                        onChange={handleTabChange}
                                        items={[
                                            {
                                                label: 'Income',
                                                key: '1',
                                                children: <Income />,
                                            },
                                            {
                                                label: 'Expenses',
                                                key: '2',
                                                children: <Expenses />,
                                            },
                                            {
                                                label: 'Withdrawal Request',
                                                key: '3',
                                                children: null,
                                            },
                                            {
                                                label: 'Bank Account',
                                                key: '4',
                                                children: <BankAccount />,
                                            },
                                        ]}
                                    />
                                ) : selectedView === 'merchant' ? (
                                    // Show merchant-specific content (could be different Tabs or a custom layout)
                                    <div className="merchant-content">
                                        <h4>Merchant Dashboard</h4>
                                        <Tabs
                                            centered
                                            activeKey={activeKey}
                                            onChange={handleTabChange}
                                            items={[
                                                {
                                                    label: 'Merchant Income',
                                                    key: '1',
                                                    children: <MerchantIncome />, // Placeholder, you can replace it with merchant-specific component
                                                },
                                                {
                                                    label: 'Merchant Expenses',
                                                    key: '2',
                                                    children: <MerchantExpenses />, // Placeholder, you can replace it with merchant-specific component
                                                },
                                            
                                            ]}
                                        />
                                    </div>
                                ) : null}
                            </div>

                            {show && (
                                <WithDrawal
                                    show={show}
                                    close={handleClose}
                                    onSubmit={() => setActiveKey('4')}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Wallet;
