import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../../services/Api';
import Loader from '../../Loader';

const CuponOfferWishList = () => {
  const [coupons, setCoupons] = useState([]); // State to hold the coupon data
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchCoupons = async () => {
      setLoading(true);
      try {
        // Fetch coupons from the API (update API endpoint if needed)
        const result = await ApiService.getwishlisttypeData('discount'); // Adjust the endpoint for your API
        setCoupons(result.data); // Assuming the API returns the data in `data`
      } catch (err) {
        if (err.status === 404) {
            setError("Wihslit discount offers not found.");
          } else if (err.status === 422) {
          setError('Invalid data received.');
        } else {
          setError('Failed to load coupons.');
        }
      } finally {
        setLoading(false); // Stop loading once data is fetched or error occurs
      }
    };

    fetchCoupons(); // Call the fetchCoupons function when component mounts
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  // Display loading spinner while fetching data
  if (loading) {
    return <Loader />;
  }

  // Display error message if an error occurred during data fetching
  // if (error) {
  //   return <div>{error}</div>;
  // }

  return (
    <div className="cupon-offer-outer">
      <div className="container">
        <div className="cupon-offer-inner">
          <div className="heading">
            <h3>COUPONS & OFFERS</h3>
          </div>

          <div className="cupon-offer row">
            {/* Map through the coupons and display them dynamically */}
            {coupons.map((coupon, index) => (
              <div className="item" key={index}>
                <div className="item-inner">
                  {/* Display the sale or offer */}
                  <Link to={`/cupondescription/${coupon.id}`}>
                  <span className="offer-sale">
                    <p>
                                        {coupon.amounttype === 'percentage'
                                            ? `Up to ${Math.round(coupon.discamount)}% off`
                                            : `Up to $${Math.round(coupon.discamount)} off`}
                                    </p>
                  </span>
                  <div className="img-content">
                    {/* Display the coupon image */}
                    <img
                      src={coupon.image_url || '/assets/Image/no-image-icon-23485.png'}
                      alt={coupon.name}
                    />
                  </div>
                  <div className="text-content">
                    {/* Link to the coupon offer or details page */}
                    <Link to={`/cupondescription/${coupon.id}`} className="button">
                      {coupon.button_text || 'Earn Rewards'} {coupon.amounttype === 'percentage'
                                            ? `${Math.round(coupon.discamount)}% off`
                                            : `$${Math.round(coupon.discamount)} off`}
                    </Link>
                    <Link to={`/cupondescription/${coupon.id}`} className="rewards">
                      Rewards Rates & Terms
                    </Link>
                  </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CuponOfferWishList;
