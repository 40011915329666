import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Spin } from 'antd';
import ApiService from '../../../services/Api';  // Assuming you have an ApiService to fetch data
import Loader from '../../Loader';
const Slider = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const defaultImage = '/assets/Image/offerCode.png'; // Fallback image path

    useEffect(() => {
        const fetchSliderData = async () => {
            try {
                const result = await ApiService.getSliderImages(); // Assuming this is your API endpoint
                if (result.status === 200 || result.status === 201) {
                    setItems(result.data);  // Assuming your API returns an array of image objects
                } else {
                    console.error("Failed to fetch slider images");
                }
            } catch (err) {
                console.error("Error fetching slider images:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchSliderData();
    }, []);  // Empty dependency array to fetch data once on mount

    return (
        <div className="slider-outer">
            <div className="container">
                <div className="slider-inner">
                    {loading ? (
                              <Loader />
                    ) : (
                        <OwlCarousel className="owl-theme" loop margin={10} items={1} nav={false}>
                            {/* If items is empty, show a single default image */}
                            {(items.length > 0 ? items : [{ id: 'default', image_url: defaultImage }]).map((item) => {
                                const imageUrl = item.image_url || defaultImage; // Use image URL from API or fallback

                                return (
                                    <div className="item" key={item.id}>
                                        <div className="item-inner">
                                            <img 
                                                src={imageUrl} 
                                                alt={item.name || 'Slider image'} 
                                                className="slider-image" 
                                                onError={(e) => { 
                                                    e.target.onerror = null; 
                                                    e.target.src = defaultImage; 
                                                }} 
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </OwlCarousel>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Slider;
