import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApiService from '../../../../services/Api';  // Assuming ApiService is imported for API calls

const WithDrawal = ({ show, close, onSubmit }) => {
    const [withdrawalAmount, setWithdrawalAmount] = useState('');
    const [isChecked, setIsChecked] = useState(false);  // State to track checkbox status
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');  // Success message state
    const [isBankVerified, setIsBankVerified] = useState(null);  // State for bank verification status

    // Retrieve balance from localStorage
    const walletBalance = localStorage.getItem('wallet_balance');

    // Fetch bank verification status
    useEffect(() => {
        const fetchBankVerificationStatus = async () => {
            try {
                const result = await ApiService.getBankVerificationStatus(); // Assuming this API fetches the verification status
                if (result.status === 200 || result.status === 201) {
                    setIsBankVerified(result.is_verified); // Assuming the API returns { is_verified: true/false }
                } else {
                    setIsBankVerified(false); // In case of an error, assume unverified
                }
            } catch (err) {
                setIsBankVerified(false); // In case of an error, assume unverified
                console.error("Error fetching bank verification status:", err);
            }
        };

        fetchBankVerificationStatus();
    }, []);  // This effect runs once when the component mounts

    // Handle input change for withdrawal amount
    const handleAmountChange = (e) => {
        setWithdrawalAmount(e.target.value);
        setError(''); // Reset error when user changes the amount
    };

    // Handle checkbox change
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked); // Update checkbox status
    };

    // Handle submit
    const handleSubmit = async () => {
        // Reset previous errors
        setError('');

        // Validate withdrawal amount
        if (!withdrawalAmount || isNaN(withdrawalAmount)) {
            setError('Please enter a valid amount');
            return;
        }
        if (parseFloat(withdrawalAmount) == 0) {
            setError('Withdrawal amount greater than 1 ruppess');
            return;
        }

        // Check if withdrawal amount exceeds wallet balance
        if (parseFloat(withdrawalAmount) > parseFloat(walletBalance)) {
            setError('Withdrawal amount cannot be greater than your balance');
            return;
        }

        // Check if the checkbox is checked
        if (!isChecked) {
            setError('You must agree to the terms before submitting');
            return;
        }

        // Check if the bank is verified
        if (!isBankVerified) {
            setError('You must verify your bank account before withdrawing');
            return;
        }

        // Prepare form data
        const formData = {
            amount: withdrawalAmount,
            // Add any additional required data here, e.g., user ID, bank account info
        };

        try {
            // Submit data to the API
            const result = await ApiService.submitWalletRequest(formData);

            // Check if the API request was successful
            if (result.status === 200 || result.status === 201) {
                setSuccessMessage('Withdrawal request submitted successfully!');
                setTimeout(() => {
                    setSuccessMessage(''); // Clear success message after a few seconds
                    close(); // Close the modal after success
                }, 3000);
                onSubmit(withdrawalAmount); // Pass the withdrawal amount to onSubmit
            } else {
                setError('Failed to process withdrawal. Please try again.');
            }

        } catch (err) {
            setError('An error occurred while processing the withdrawal.');
            console.error(err); // Log error for debugging
        }
    };

    const redirectBank = () => {
        close(); // Close the modal
        onSubmit(); // Call the onSubmit prop, maybe to handle redirection or logic
    }

    return (
        <div className="withdrawal-request-outer">
            <Modal show={show} onHide={close}>
                <div className="withdrawal-outer">
                    <div className="withdrawal-inner">
                        <div className="heading">
                            <h3>Enter Withdrawal Amount</h3>
                        </div>

                        <div className="withdrawal-amount">
                            <input
                                type="text"
                                placeholder="Enter Withdrawal Amount"
                                value={withdrawalAmount}
                                onChange={handleAmountChange}
                            />
                        </div>

                        {/* Error Message */}
                        {error && <p className="error">{error}</p>}

                        {/* Success Message */}
                        {successMessage && <p className="success">{successMessage}</p>}

                        {/* Bank Account Verification Status */}
                        {!isBankVerified && (
                            <p className="warning">
                                ! Your bank account is not verified.{' '}
                                <Link to="#" onClick={redirectBank}>
                                    Click here to verify.
                                </Link>
                            </p>
                        )}

                        {/* Checkbox Validation */}
                        <div className="check-group">
                            <input
                                type="checkbox"
                                name="terms-checkbox"
                                id="terms-checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <label htmlFor="terms-checkbox">
                                Your request will be verified by Camdell. After verification, the amount will be transferred to your account. The verification process may take some time.
                            </label>
                        </div>

                        {/* Submit Button */}
                        <div className="withdrawal-button">
                            <button onClick={handleSubmit} className="button">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default WithDrawal;
