import React, { useEffect, useState } from 'react';
import { FaCirclePlus } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import ApiService from '../../../services/Api';
import { Spin } from 'antd'; 
import Loader from '../../Loader';
const YourCupon = () => {
    const [coupon, setCoupon] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        handleGetCouponData();
    }, []);

    const handleGetCouponData = async () => {
        try {
            const result = await ApiService.getCouponData(); 
            if (result.status === 200 || result.status === 201) {
                setCoupon(result.data);
                setShow(true);
            } 
        } catch (err) {
            if (err.status === 422) {
                setErrors(err.errors);
            } else {
                // Handle general errors (optional)
            }
        } finally {
            setLoading(false);
        }
    };

    const getStatus = (status) => {
        switch (status) {
            case 0:
                return { text: 'Pending', className: 'pending' };
            case 1:
                return { text: 'Approved', className: 'approved' };
            case 2:
                return { text: 'Rejected', className: 'rejected' };
            default:
                return { text: 'Unknown', className: 'unknown' };
        }
    };
    
    return (
        <>
            <div className="your-cupon-outer">
                <div className="container">
                    <div className="your-cupon-inner cupon-row">
                        <div className="row">
                            {loading ? (
                                <Loader />
                            ) : coupon.length === 0 ? (
                                <p>No coupons available.</p>
                            ) : (
                                coupon.map((i) => {
                                    const { text, className } = getStatus(i.is_approved);
                                    return (
                                        <div key={i.id} className="item col-12 col-md-6 col-xl-4">
                                            <div className="item-inner">
                                                <div className="cupon-status">
                                                    <span className={className}>{text}</span>
                                                </div>
                                                <div className="top">
                                                    <div className="img-content">
                                                        <img 
                                                            src={i.image_url ? i.image_url : '/assets/Image/no-image-icon-23485.png'} 
                                                            alt={i.name} 
                                                        />
                                                        <h2>{i.name}</h2>
                                                        <div className="cupon-mode">
                                                            {i.cuponused}
                                                        </div>
                                                    </div>
                                                    <div className="add-new">
                                                        <Link to={`/mangeradsprmotion/${i.id}`}>View All</Link>
                                                      </div>
                                                </div>
                                                <div className="bottom row">
                                                    <div className="col-6"><b>Cat:</b> {i.category_name}</div>
                                                    <div className="col-6"><b>Started:</b> {i.startdate}</div>
                                                    <div className="col-6"><b>Sub-Cat:</b> {i.subcategory_name}</div>
                                                    <div className="col-6"><b>Ended:</b> {i.enddate}</div>
                                                    <div className="col-6"><b>Deals:</b> {i.ctype}</div>
                                                    <div className="col-12 text-end">
                                                        <Link  to={`/operatorstatic/${i.id}`}>View Statistics</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default YourCupon;
