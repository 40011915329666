import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ApiService from '../../../services/Api';

const TypeOfCategory = () => {
    const [items, setItems] = useState([]); 
    const [loading, setLoading] = useState(true); // Loading state
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({});
    
    const options = {
        loop: false,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 3,
            },
            700: {
                items: 5,
            },
            1200: {
                items: 7,
            }
        },
    };

    useEffect(() => {
        handleGetCategory();
    }, []);

    const handleGetCategory = async () => {
        try {
            const result = await ApiService.category(); 
            console.log(result);
            if (result.status === 200 || result.status === 201) {
                setItems(result.data);
                setShow(true);
                console.log(items);
            } else {
                console.error("Failed to fetch categories");
            }
        } catch (err) {
            if (err.status === 422) {
                setErrors(err.errors); 
            } else {
                console.error("An error occurred:", err);
            }
        } finally {
            setLoading(false); // Set loading to false after data is fetched
        }
    };

    return (
        <div className="category-outer">
            <div className="container">
                <div className="category-inner">
                    <div className="heading">
                        <h3>TYPES OF CATEGORIES</h3>
                    </div>
                    {loading ? (
                        <p>Loading categories...</p> // Display loading message
                    ) : (
                        show && items.length > 0 ? (
                            <OwlCarousel className="owl-theme" {...options}>
                                {items.map((i, index) => (
                                    <div className="item" key={index}>
                                      <Link to={`/category/${i.slug}/${i.id}`}>
                                            <div className="item-inner">
                                                <div className="img-content">
                                                    <img src={i.image_url ? i.image_url : '/assets/Image/no-image-icon-23485.png'} 
                                                            alt={i.category_name} 
                                                        />
                                                </div>
                                                <div className="text-content">
                                                    <p className='black'>{i.category_name}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </OwlCarousel>
                        ) : (
                            <p>No categories available.</p> // Fallback if no items are returned
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default TypeOfCategory;
