import React, { useState, useEffect } from 'react';
import Slider from '../User/Home/Slider';
import TypeOfCategory from '../User/Home/TypeOfCategory';
import CashBack from '../User/Home/CashBack';
import Exclusive from '../User/Home/Exclusive';
import Treandincupon from '../User/Home/Treandincupon';
import Voucher from '../User/Home/Voucher';
import Subscribe from '../User/Home/Subscribe';
import Header from '../Header';
import Store from '../User/Home/Store'
import ReferralPopup from '../User/ReferralPopup';

const UserDashboard = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const showModal = localStorage.getItem('showreff') === 'true';
        if (showModal) {
            setModalOpen(true);
        }
    }, []);

    const closeModal = () => {
        setModalOpen(false);
        localStorage.setItem('showreff', 'false'); // Set to false to not show again
    };

    // For debugging, you can log the state of the modal
    useEffect(() => {
        console.log('Modal state:', isModalOpen);
    }, [isModalOpen]);

    return (
        <>
            <Header />
            <Slider />
            <TypeOfCategory />
            <CashBack />
            <Exclusive />
            <Treandincupon />
            <Store />
            <Voucher />
            <Subscribe />
            <ReferralPopup isOpen={isModalOpen} onClose={closeModal} />
        </>
    );
};

export default UserDashboard;
