import React from 'react'
import { Link } from 'react-router-dom'

const ApprovalRejected = ({close}) => {
    return (
        <>
            <div className="approval-outer">
                <div className="approval-inner">
                    <div className="heading">
                        <h3>
                        Approval Rejectd
                        </h3>
                    </div>
                    <p>Once Rejected you can't Access the Merchant.Please contact Camdell Support</p>
                    <Link to={'/'} className="button" onClick={close}>Back</Link>
                </div>
            </div>
        </>
    )
}

export default ApprovalRejected
