import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CuponCodeModal from './CuponCodeModal';
import UsercuponModal from './UsercuponModal';
import PurchasingAmount from './PurchasingAmount';
import SuccessfulModal from './SuccessfulModal';
import ApiService from '../../../services/Api';

const Scanner = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [uniqueCode, setUniqueCode] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [errors, setErrors] = useState({});
  const handleClose = () => {
    setShowModal(false);
    setErrorMessage(null);
  };
  const handleUserCuponShow = () => {
      const couponDetails = localStorage.getItem('couponDetails');

      if (couponDetails) {
        const userData = JSON.parse(couponDetails);
        setModalContent(<UsercuponModal userData={userData} onClose={handleClose} onOpenSuccessfull={handleSuccessful} />);
        setShowModal(true);
      } else {
        console.log('No coupon details found in localStorage');
      }
    
  };

  const handlePurchasing = () => {
    setModalContent(<PurchasingAmount onClose={handleClose} onOpenSuccessful={handleSuccessful} />);
    setShowModal(true);
  };

  const handleSuccessful = () => {
    setModalContent(<SuccessfulModal onClose={handleClose} />);
    setShowModal(true);
  };

  const handleVerify = async () => { 
    setLoading(true);
    setErrorMessage(null);
    try {
      const usercode = { uniqueCode };
      const result = await ApiService.verifyUserCode(usercode);

      if (result.status === 200) {
        const data = result.data;
        setUserData(data);
        setModalContent(
          <CuponCodeModal 
            userData={data} 
            onClose={handleClose}  
            onOpenUserCupon={handleUserCuponShow} 
          />
        );
        setShowModal(true);
        setUniqueCode('');
      } else if (result.status === 404) {
        setErrorMessage('User does not exist.');
      }
    } catch (err) {
     if(err.status === 422){
      setErrors(err.errors);
     }
     if(err.status === 404){
      setErrorMessage(err.errors);
     }
     else{

       setErrorMessage(err.status === 422 ? err.errors : 'An error occurred while fetching user details. Please try again.');
     }
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setUniqueCode(e.target.value);
  };

  return (
    <>
      <div className="scanner-outer">
        <div className="container">
          <div className="scanner-inner">
            <div className="qr-image">
              <div className="img-content">
                <img src="assets/Image/QR-Code.png" alt="" />
              </div>
            </div>

            <div className="or"><span>(or)</span></div>

            <div className="number-box">
              <div className="inner-box">
                <div className="heading">
                  <h3>Enter 16 Digit Number</h3>
                  <span>Offline coupon code get from shop</span>
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    placeholder='Enter 16 Digit number'
                    value={uniqueCode}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="submit-button">
                  <Link className="button" onClick={handleVerify}>Submit</Link>
                </div>
                {errorMessage && <div className="mt-3 error row justify-content-center">{errorMessage}</div>}
                {errors.uniqueCode && <div className="mt-3 error row justify-content-center">{errors.uniqueCode}</div>}
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        {loading ? <div>Loading...</div> : modalContent}
      </Modal>
    </>
  );
}

export default Scanner;
