import React from 'react'
import CashbackStore from './UsedCoupon/CashbackStore'
import CuponOffer from './UsedCoupon/CuponOffer'
import VoucherCupon from './UsedCoupon/VoucherCupon'

const UsedCoupon = () => {
    return (
        <>
            <div className="mycupon-outer">
                
                        <CashbackStore />
                        <CuponOffer />
                        <VoucherCupon />
                    </div>
                
        </>
    )
}

export default UsedCoupon