import React from 'react'
import OperatorHeader from './OperatorHeader'
import PageBanner from '../PageBanner'
import EditNewDeasl from './AddNewDeals/EditNewDeasl'

const CouponEdit = () => {
  return ( 
    <>
    <OperatorHeader/>
      <PageBanner title='Merchant'/>
      <EditNewDeasl/>
    </> 
  )
}

export default CouponEdit;
