import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams from React Router
import { VictoryArea, VictoryChart, VictoryAxis, VictoryStack } from 'victory';
import ApiService from '../../../services/Api';

const ChartProgress = () => {
    const { couponid } = useParams();  // Extract couponid from URL
    const [progressData, setProgressData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch coupon data when couponid changes
    useEffect(() => {
        handleGetCouponData();
    }, [couponid]);  // Re-fetch when couponid changes

    const handleGetCouponData = async () => {
        setLoading(true);
        setError(null);  // Reset error state
        try {
            // Call the API to fetch data based on the couponid
            const result = await ApiService.ChartData(couponid);
            setProgressData(result.data);  // Assuming the API response is structured as { data: ... }
        } catch (err) {
            setError('Error fetching coupon data');
            console.error('API Error:', err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!progressData) {
        return <div>No data available</div>;
    }

    const { percentage, color, title, usedCount, couponCount } = progressData;

    const progress = [
        {
            persntage: percentage.toString(),
            color: color,
            title: title
        }
    ];

    const calculateDataSize = (total, percentages) => {
        const totalPercentage = percentages.reduce((acc, cur) => acc + cur, 0);
        return percentages.map(percentage => (total * percentage) / totalPercentage);
    };

    const percentages = [percentage];
    const totalDataSize = 100;
    const dataSize = calculateDataSize(totalDataSize, percentages);

    const data = dataSize.map((size, index) => {
        const dataSet = [];
        for (let i = 1; i <= 25; i++) {
            dataSet.push({ x: i, y: Math.floor(Math.random() * 10) + 1 });
        }
        return dataSet;
    });

    return (
        <div className="chart-progress-outer">
            <div className="chart-progress-inner">
                <div className="left">
                    <div className="text-inner">
                        {progress.map((i) => {
                            return (
                                <div className="data-info" key={i.title}>
                                    <div className="persentage-text" style={{ backgroundColor: `${i.color}` }}>
                                        <span>{i.persntage}%</span>
                                    </div>
                                    <div className="offer-text">{i.title}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="right">
                    <div className="chart-table">
                        <VictoryChart width={1200} height={450}>
                            <VictoryAxis style={{
                                axis: { stroke: "#7D7979", strokeDasharray: "5, 5" },
                                tickLabels: { fill: "none" },
                                grid: { stroke: "none" }
                            }} />
                            <VictoryAxis dependentAxis style={{
                                axis: { stroke: "#7D7979", strokeDasharray: "5, 5" },
                                tickLabels: { fill: "none" },
                                grid: { stroke: "#D1D1D1", strokeDasharray: "5, 5" }
                            }} />
                            <VictoryStack colorScale={progress.map(i => i.color)}>
                                {data.map((dataSet, index) => (
                                    <VictoryArea key={index} data={dataSet} interpolation="natural" />
                                ))}
                            </VictoryStack>
                        </VictoryChart>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChartProgress;
