import React from 'react'
import Header from '../Header'
import PageBanner from '../PageBanner'
import GetDescription from '../User/GetDescription'

const CuponDescription = () => {
    return (
        <>
        <Header />
            <PageBanner title='Get coupons' />
            <GetDescription />
        </>
    )
}

export default CuponDescription