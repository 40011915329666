import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../services/Api';

const DeleteAccount = ({ onClose }) => {
  const [loading, setLoading] = useState(false); // To track loading state
  const [error, setError] = useState(null); // To track errors
  const [success, setSuccess] = useState(null); // To track success state

  const handleDelete = async () => {
    setLoading(true); // Start loading
    setError(null); // Clear previous errors
    setSuccess(null); // Clear previous success messages

    try {
      const result = await ApiService.deleteAccount(); // Call the API service to delete the account
      setSuccess('Your account has been verify and delete the account within 4 days.');
      onClose(); // Close the modal after success
    } catch (err) {
      setError(err.message); // Set error message if deletion fails
    } finally {
      setLoading(false); // Stop loading after the request completes
    }
  };

  return (
    <>
      <div className="delete-account-outer">
        <div className="delete-account-inner">
          <div className="heading">
            <h3>Terms & Condition</h3>
          </div>

          <p>Are you sure you want to delete your account? Once confirmed, we will verify and delete the account within 4 days. If you change your mind, you can login again before 4 days.</p>

          {error && <p className="error-message">{error}</p>} {/* Show error message if deletion fails */}
          {success && <p className="success-message">{success}</p>} {/* Show success message if account is deleted */}
          
          <div className="button-container">
            <Link onClick={handleDelete} disabled={loading}>
              {loading ? 'Deleting...' : 'confirm'}
            </Link>
            <Link onClick={onClose}>Cancel</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteAccount;
