import React, { useEffect, useState, useRef } from 'react'; 
import { Link, useParams, useNavigate } from 'react-router-dom'; 
import SubTermsCondition from './SubTermsCondition';
import { Modal } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { PiFilesFill } from 'react-icons/pi';
import ApiService from '../../services/Api';
import { FaDownload } from 'react-icons/fa6';
import { BaseUrl } from '../BaseURL/BaseUrl';
import { Spin } from 'antd'; 
import Loader from '../Loader';
const GetDescription = () => {
    const { couponid } = useParams(); 
    const [couponData, setCouponData] = useState(null); 
    const [errorMessage, setErrorMessage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [loading, setLoading] = useState(true); 
    const [hasSubscription, setHasSubscription] = useState(false); 
    const [codeLoading, setCodeLoading] = useState(false); 
    const navigate = useNavigate(); 
    const [isApproved, setIsApproved] = useState(false);
    const couponGridRef = useRef(null);
    const handleClose = () => setShowModal(false);
    const [isInWishlist, setIsInWishlist] = useState(false);
    const handleSubterms = () => {
        setModalContent(<SubTermsCondition onClose={handleClose} />);
        setShowModal(true);
    };
 
    const handleGetCode = async () => {
        try { 
            setCodeLoading(true); // Set coupon code loading
            const result = await ApiService.getCouponCode(couponid);
            if (result.status === 200) {
                setCouponData(result.data); 
                couponGridRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            if (result.status === 422) {
                setErrorMessage(result.errors);
            }
        } catch (error) {
            console.error('Error fetching coupon code:', error);
        } finally {
            setCodeLoading(false); // Stop loading after the call
        }
    };

    useEffect(() => {
        const fetchCouponDetails = async () => {
            setLoading(true); 
            try {
                const result = await ApiService.getUsedcoupon(couponid,'usedcode');
                if (result.status === 200 || result.status === 201) {
                    setCouponData(result.data);
                    setIsInWishlist(result.data.wishlist);
                } else {
                    console.error("Failed to fetch coupon details");
                }
            } catch (err) {
                console.error("Error fetching coupon details:", err);
            } finally {
                setLoading(false);
            }
        };

        const checkSubscriptionStatus = async () => {
            try {
                const result = await ApiService.checkSubscription(); 
                if (result.status === 200 && result.active) {
                    setHasSubscription(true); 
                    await fetchCouponDetails(); 
                } else {
                    navigate('/subscription'); 
                }
            } catch (err) {
                console.error("Error checking subscription status:", err);
                navigate('/subscription'); 
            }
        };
        const approvedStatus = localStorage.getItem('is_approved');
       
        if(approvedStatus === 2){
            setIsApproved(true); 
        }
        

        if (couponid) {
            checkSubscriptionStatus(); 
        }
    }, [couponid, navigate]);
   
    const toggleWishlist = async (couponid, isCurrentlyInWishlist) => {
        try {
            // Prepare the method for the API call (POST to add, DELETE to remove)
            const method = isCurrentlyInWishlist ? 'DELETE' : 'POST';
    
            // Send the request to toggle the wishlist status
            const response = await fetch(`${BaseUrl}/user/wishlist/${couponid}`, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`, // Assuming you have a token stored in localStorage
                },
                // Only include body when adding to wishlist (POST request)
                body: isCurrentlyInWishlist ? null : JSON.stringify({ coupon_id: couponid }), // Adjust if necessary
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw { status: response.status, errors: errorData.errors || {} }; // Handle errors if the response is not OK
            }
    
            // Parse the response if successful
            const result = await response.json();
    
            // Update the wishlist state after a successful API call
            setIsInWishlist(!isCurrentlyInWishlist);  // Toggle the wishlist state
        } catch (error) {
            console.error('Error toggling wishlist:', error);
            setErrorMessage('An error occurred while updating your wishlist.'); // Set an error message if something goes wrong
        }
    };
    
    if (loading) {
        
        return <Loader />;
    }

    const imageUrl = couponData?.image_url || '/assets/Image/no-image-icon-23485.png';

    const slider = [
        { img: couponData?.image_url || '/assets/Image/no-image-icon-23485.png' },
        { img: couponData?.image_url || '/assets/Image/no-image-icon-23485.png' },
        { img: couponData?.image_url || '/assets/Image/no-image-icon-23485.png' },
    ];

    const items = couponData ? [
        { heading: 'Categories', info: couponData.category_name || "N/A" },
        { heading: 'Sub - categories', info: couponData.subcategory_name || "N/A" },
        { heading: 'No of year trading', info: couponData.trading_year || "N/A" },
        { heading: 'Business type', info: couponData.btype || "N/A" },
        { heading: 'Business Relationship', info: couponData.businessrelation || "N/A" },
        { heading: 'Restrictions', info: couponData.registrationnumber || "N/A" },
        { heading: 'Coupon Type', info: couponData.ctype || "N/A" },
        { heading: 'Coupons Used on', info: couponData.cuponused || "N/A" },
        { heading: 'Available coupons', info: couponData.couponcount || "N/A" },
        { heading: 'Started Deals', info: couponData.startdate || "N/A" },
        { heading: 'Ended Deals', info: couponData.enddate || "N/A" },
        { heading: 'Used coupons', info: couponData.cuponused || "N/A" },
        { heading: 'Get Discount', info: couponData.discamount || "N/A" },
        { heading: 'Min-Purchase', info: couponData.miniamount || "N/A" },
        { heading: 'Max-Purchase', info: couponData.maxamount || "N/A" },
        { heading: 'Website link', info: couponData.website || "N/A" },
    ] : [];

    return ( 
        <>
            <div className="cupon-description-outer">
                <div className="container">
                    <div className="cupon-description-inner">
                      

                        <div className="heading">
                            <h3>{couponData?.name || "Loading..."}</h3>
                            <Link className='button'>{couponData?.cuponused}</Link>
                            <div className="wishlist-section">
                            <button
                                className={`mb-3 wishlist-button ${isInWishlist ? 'active' : ''}`}
                                onClick={() => toggleWishlist(couponData.id, isInWishlist)}  // Pass the coupon id and wishlist status
                                aria-label={isInWishlist ? 'Remove from Wishlist' : 'Add to Wishlist'}
                            >
                                <i 
                                    className={`fa fa-heart ${isInWishlist ? 'text-danger' : 'text-grey'}`} 
                                    aria-hidden="true"
                                    style={{ fontSize: '24px' }}  // Optional: Adjust size of the heart icon
                                />
                            </button>
                        </div>
                         
                        </div>

                        <div className="img-cupon-content row">
                            <div className="col-md-8 col-12 img-content">
                                <div className="row">
                                    <div className="col-3 img-group">
                                    {couponData.image_url.map((image, index) => {
                                                const fixedFullPath = image.full_path.replace(/\\\//g, '/');
                                                return (
                                                    <div className="img-content mt-2" key={index}>
                                                            <img src={fixedFullPath} alt={`Image ${index}`} />
                                                    </div>
                                                );
                                            })}
                                    </div>
                                    <div className="col-12 col-lg-9 slider-img">
                                        <OwlCarousel className='owl-theme' loop margin={10} items={1} nav={false}>
                                        {couponData.image_url.map((image, index) => {
                                                const fixedFullPath = image.full_path.replace(/\\\//g, '/');
                                                return (
                                                    <div className="img-item" key={index}>
                                                        <div className="img-inner">
                                                            <img src={fixedFullPath} alt={`Image ${index}`} />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div className="item">
                                    <div className="item-inner">
                                       <span className="offer-sale">
                                        {couponData.amounttype === 'percentage' 
                                            ? `Up to ${couponData.discamount}% off` 
                                            : `Up to $${couponData.discamount} off`}
                                        </span>
                                        <div className="img-content">
                                            <img src={imageUrl || '/assets/Image/no-image-icon-23485.png'} alt='' />
                                        </div>
                                        <div className="text-content">
                                            <Link className="button" to="/">
                                            {couponData.amounttype === 'percentage' 
                                            ? `Earn Up to ${couponData.discamount}% Offers` 
                                            : `Earn Up to $${couponData.discamount} Offers`}
                                            </Link>
                                            <Link className="rewards" to="" onClick={handleSubterms}>Rewards Rates & terms</Link>
                                        </div>
                                    </div>
                                </div>
                               
                                {errorMessage && (
                                    <div className="mt-3 error">
                                        {errorMessage}
                                    </div>
                                )}
                            </div>
                        </div> 

                        <div className="note">
                        {couponData.totalLoayltyPoints == 0 && (
                        <p><b>Note</b>: When you purchase this coupon you can get some loyalty points.</p>
                        )}
                             {couponData.totalLoayltyPoints !== 0 && (
                            <p><b>Your Loyalty Points</b>: {couponData.totalLoayltyPoints}<img src="/assets/Image/Coins.png" alt="Coins Logo" width={"30px"} height={"30px"} /> </p>
                             )}
                        </div>

                        {couponData && (
                            <div className="coupon-grid mt-3" ref={couponGridRef}> 
                                {couponData.coupon_codes.length > 0 ? (
                                    couponData.coupon_codes.map((coupon, index) => (
                                        <div key={index} className="cupon-code"> 
                                            <div className="left">
                                                <h4>COUPON CODE</h4>
                                                <div className="code">
                                                    <span>code</span>
                                                    <div className="icon">
                                                        <PiFilesFill />
                                                    </div>
                                                    <div className="code-number">
                                                        {codeLoading ? "Loading..." : coupon.code || "Loading..."} {/* Use codeLoading for this part */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rotate-text">
                                                <span>COUPON CODE</span>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="cupon-code">
                                        <div className="left">
                                            <h4>No Coupon Codes Available</h4>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        <ul className="services-info row">
                            {
                                items.map((i, index) => (
                                    <li className="col-12 col-md-4" key={index}>
                                        <p><b>{i.heading}</b>: <span>{i.info}</span></p>
                                    </li>
                                ))
                            }
                        </ul>

                        <div className="description">
                            <div className="heading">
                                <h4>Description</h4>
                            </div>
                            <p>{couponData.description}</p>
                        </div>

                        <div className="terms-condition row">
                            <div className="col-12 col-md-6 item">
                                <div className="heading">
                                    <h4>Terms & conditions </h4>
                                </div>
                                <ul>
                                    <li>{couponData.condition}</li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6 item">
                                <div className="heading">
                                    <h4>How to use</h4>
                                </div>
                                <p>{couponData.howusecupon}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleClose}>
                {modalContent}
            </Modal>
        </>
    );
}

export default GetDescription;
