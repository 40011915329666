import React, { useState } from 'react';
import { FaX } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import ApiService from '../../services/Api';

const ForgetPassword = ({ onClose, onOpenMemberLogin }) => {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [submitError, setSubmitError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        const { value } = e.target;
        setEmail(value);
        setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
        setSubmitError('');
    };

    const validateForm = () => {
        const newErrors = {};
        if (!email) newErrors.email = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = 'Email is invalid';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const result = await ApiService.forgotPassword({ email });
            if (result && result.status === 200) {
                setSuccessMessage('A password reset link has been sent to your email.');
                setTimeout(() => {
                    onOpenMemberLogin();  // Redirect or open login form
                }, 2000);
            } else {
                // Handle unexpected response structure
                setSubmitError(result?.message || 'Password reset failed. Please try again.');
            }
        } catch (err) {
            setSubmitError(err.message || 'An unexpected error occurred.');
        }
    };

    return (
        <>
            <div className="signup-outer">
                <div className="signup-inner row">
                    <div className="col-12 col-sm-6 left">
                        <div className="img-content">
                            <img src="/assets/Image/Discount.png" alt="" />
                            <img src="/assets/Image/signupcuponbg.png" alt="" />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 right">
                        <button onClick={onClose}><FaX /></button>
                        <div className="inner">
                            <div className="heading">
                                <h3>Forget Password</h3>
                            </div>
                            <form method="post" onSubmit={handleSubmit}>
                                <input
                                    type="email"
                                    name='email'
                                    placeholder='Enter your registered email'
                                    value={email}
                                    onChange={handleChange}
                                />
                                {errors.email && <span className="error">{errors.email}</span>}

                                <Link to='' className='button' onClick={handleSubmit}>Send Reset Link</Link>
                                {submitError && <span className="error">{submitError}</span>}
                                {successMessage && <span className="success">{successMessage}</span>}

                                <div className="sign-in">Remembered your password? <Link to='' onClick={onOpenMemberLogin}>Sign in</Link></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgetPassword;
