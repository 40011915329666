import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Spin } from 'antd'; 
import ApiService from '../../services/Api'; // Import your API service
import Loader from '../Loader';
const Allcupon = () => {
    const { catid, subid } = useParams();  // Get catid and subid from URL parameters
    const [coupons, setCoupons] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch coupons from the API
    useEffect(() => {
        const fetchCoupons = async () => {
            setLoading(true);
            try {
                const result = await ApiService.getCoupons(catid, subid);
                setCoupons(result.data); // Assuming your API returns the data in `data` field
            } catch (err) {
                if(err.status===404){
                    setError(err.errors);
                }else{

                    setError('Failed to load coupons.');
                }
                // console.error('Error fetching coupon data:', err);
            } finally {
                setLoading(false);
            }
        };

        if (catid && subid) {
            fetchCoupons();
        }
    }, [catid, subid]);

    if (loading) {
        
        return <Loader />;
    }

    if (error) {
        return <div>{error}</div>;
    }
    const handleCouponClick = (couponId) => {
        localStorage.removeItem('couponId');
        localStorage.setItem('couponId', couponId);
    };

    return (  
        <>
            <div className="cupon-outer">
                <div className="container">
                    <div className="cupon-inner row">
                        {coupons.map((coupon, index) => (
                            <div className="item" key={index}>
                                <div className="item-inner">
                                    <div className="img-content">
                                        <img 
                                            src={coupon.image_url ? coupon.image_url.replace(/\\\//g, '/') : '/assets/Image/no-image-icon-23485.png'} 
                                            alt='' 
                                            />
                                    </div>
                                    <div className="text-content">
                                        <p>{coupon.name || 'Special Offer'}</p>
                                        <div className="cupon-button">
                                            <Link to={`/cupondescription/${coupon.id}`} className='cupon-code-button'  onClick={() => handleCouponClick(coupon.id)}>
                                                <span className='cupon'>Get Coupons</span>
                                                <span className='code'>{coupon.code || 'xxxx'}</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Allcupon;
