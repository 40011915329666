import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PartnerSlider from './PartnerSlider'
import ApiService from '../../../services/Api'; // Assuming ApiService is set up similarly to fetch store data
import { Spin } from 'antd'; 
import Loader from '../../Loader';
const Store = () => {
    const [storeData, setStoreData] = useState(null); // State to store the fetched store data
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state for fetching data

    // Fetch store data when the component mounts
    useEffect(() => {
        const fetchStore = async () => {
            try {
                const response = await ApiService.getPopularStore(); // Assuming this API call fetches popular store data
                if (response.status === 200) {
                    setStoreData(response.data); // Set fetched store data to state
                } else {
                    // setError('Failed to fetch popular store data');
                }
            } catch (err) {
                console.error('Error fetching store data:', err);
                // setError('An error occurred while fetching store data');
            } finally {
                setLoading(false); // Set loading to false once data is fetched
            }
        };

        fetchStore();
    }, []); // Empty dependency array means this runs once when the component mounts

    // If the data is still loading
    if (loading) {
        
        return <Loader />;
    }

    // If there is an error fetching the data
    // if (error) {
    //     return <p>{error}</p>;
    // }

    // If there is no store data
    if (!storeData) {
        return (
            <div className="store-outer">
                <div className="container">
                    <div className="store-inner">
                        <div className="heading">
                            <h5>No Store Data Found</h5>
                        </div>
                        <div className="no-store-message">
                            <p>No store data available at the moment. Please try again later.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="store-outer">
            <div className="container">
                <div className="store-inner">
                    <div className="heading">
                        <h3>Popular Store</h3>
                    </div>

                    <div className="row">
                        <div className="left col-12 col-lg-3">
                            <div className="inner">
                                <div className="text-inner">
                                    <div className="heading">
                                        <h3>
                                            Popular Store
                                        </h3>
                                    </div>
                                    <div className="img-content">
                                        {/* Replace static image with dynamic image */}
                                        <img 
                                            src={storeData.image || 'assets/Image/Naykaa.png'} 
                                            alt={storeData.name || 'Store'} 
                                        />
                                    </div>
                                    <div className="text-content">
                                        {/* Replace static text with dynamic offer */}
                                        <p>{storeData.offerText || 'Up to 760% off + Extra 5% offer on EMI'}</p>
                                        {/* Dynamically set the voucher link */}
                                        <Link to={`/store-vouchers/${storeData.id}`} className='button'>Vouchers</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right col-12 col-lg-9">
                            <div className="inner">
                                <PartnerSlider />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Store;
