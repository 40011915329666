import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../../services/Api'; // Adjust the import path as needed

const UsercuponModal = ({ userData, onClose, onOpenSuccessfull }) => {
  console.log(userData); // Ensure userData is logged correctly

  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState('');

  const handleAmountChange = (e) => {
    const value = e.target.value;
    // Only allow numeric values
    if (/^\d*$/.test(value)) {
      setAmount(value);
      setAmountError(''); // Reset the error when the user starts typing
    }
  };

  const handleButtonClick = async () => {
    const minAmount = userData.miniamount;
    const maxAmount = userData.maxamount;
    const amountFloat = parseFloat(amount);

    // Validate the amount
    if (!amount || amountFloat < minAmount || amountFloat > maxAmount) {
      setAmountError(`Amount must be between ${minAmount} and ${maxAmount}.`);
      return;
    }

    setLoading(true);
    // setMessage('Submitting...');

    const user_id = userData.purchaser_id; 
    const coupon_id = userData.id; 
    const unique_code = userData.unique_code; 
    const verifyData = {
      user_id,
      coupon_id,
      unique_code,
      purchase_amount: amountFloat, // Convert to float for the API call
    };

    try {
      const result = await ApiService.applyCodeForUser(verifyData);
      if (result.status === 200 || result.status === 201) {
        setMessage('Submitted successfully!');
        // clera all details
        localStorage.removeItem('couponDetails');
       
        onOpenSuccessfull();
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        setMessage('Submission failed. Please try again.');
      }
    } catch (error) { 
      setMessage('');
      if(error.status===422){

        setErrorMessage(error.errors);
      }
      else{
        setErrorMessage(error.errors);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="user-cupon-outer">
      <div className="user-cupon-inner">
        <div className="user-info">
          <div className="img-content">
            <img src="assets/Image/user6.jpeg" alt="" />
          </div>
          <div className="text-content">
            <div className="user-name">{userData ? userData.name : 'User Name'}</div>
            <div className="user-email">{userData ? userData.email : 'User Email'}</div>
          </div>
        </div>

        <div className="cupon-row">
          <div className="item">
            <div className="item-inner">
              <div className="top">
                <div className="img-content">
                  <img src={userData.image_url || '/assets/Image/no-image-icon-23485.png'} alt="" />
                  <h2>{userData.shop_name || 'Coupon Name'}</h2>
                </div>
              </div>
              <div className="bottom row">
                <div className="col-6">Cat: {userData.category_name || 'Category Name'}</div>
                <div className="col-6">Started: {userData.startdate ? new Date(userData.startdate).toLocaleDateString() : 'Start Date'}</div>
                <div className="col-6">Sub-Cat: {userData.subcategory_name || 'Subcategory Name'}</div>
                <div className="col-6">Ended: {userData.enddate ? new Date(userData.enddate).toLocaleDateString() : 'End Date'}</div>
                <div className="col-6">Min amount: {userData.miniamount || 'Minimum Amount'}</div>
                <div className="col-6">Max amount: {userData.maxamount || 'Maximum Amount'}</div>
                <div className="col-6">Deals: {userData.ctype || 'Coupon Type'}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="purchasing-amount">
          <label htmlFor="">Enter Purchasing Amount</label>
          <input
            type="number"
            placeholder='Enter amount'
            value={amount}
            onChange={handleAmountChange}
          />
          {amountError && <div className="error">{amountError}</div>}
        </div>

        <div className="submit-button">
          <Link className='button' onClick={handleButtonClick} disabled={loading || !amount}>
            {loading ? 'Submitting...' : 'Submit'}
          </Link>
        </div>

        {message && (
          <div className="message">
            <span>{message}</span>
          </div>
        )}

        {errorMessage && (
          <div className="error mt-3">
            <span>{errorMessage}</span>
          </div>
        )}


      </div>
    </div>
  );
};

export default UsercuponModal;
