import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element, allowedRoles }) => {
  const role = localStorage.getItem('role');
  const isApproved = localStorage.getItem('is_approved');

  // Redirect if not logged in
  if (!role) {
    alert("Please login to continue");
    return <Navigate to="/home" />;
  }

  // Check if the user is approved for merchant access
  if (role === 'user' || role === 'merchant') {
    if (isApproved === '2') {
      // If approved, allow access to both merchant and user pages
      if (allowedRoles.includes('merchant') || allowedRoles.includes('user')) {
        return element; // Allow access to either user or merchant pages
      } else {
        return <Navigate to="/unauthorized" />; // Redirect if not authorized
      }
    } else {
      // If not approved, restrict to user pages only
      if (allowedRoles.includes('user')) {
        return element; // Allow access to user pages
      } else {
        return <Navigate to="/unauthorized" />; // Redirect if not authorized for user pages
      }
    }
  }
  else{
    if (allowedRoles.includes(role)) {  
      return element; 
    }
    else{
      if (!allowedRoles.includes(role)) {
        return <Navigate to="/unauthorized" />;
      }
    }
    
  }

  // If role is not recognized, redirect to unauthorized
  return <Navigate to="/unauthorized" />;
};

export default ProtectedRoute;
