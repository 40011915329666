import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import ApiService from '../../../services/Api';
import Loader from '../../Loader';

const UsedCupon = ({ refresh }) => {
    const [cupon, setCupon] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({
        categories: '',
        subcategories: '',
        date: '',
        time: '',
        deals: '',
    });
    const [errors, setErrors] = useState({});
    const [filterOptions, setFilterOptions] = useState({
        categories: [],
        subcategories: [],
        dates: [],
        times: [],
        deals: [],
    });

    // Fetch coupon data whenever filters or refresh change
    useEffect(() => {
        handleGetCuponData();
    }, [refresh, filters]); 

    const handleGetCuponData = async () => {
        setLoading(true);
        try {
            const result = await ApiService.getUsedCouponData(filters); // Pass filters to API call
            if (result.status === 200 || result.status === 201) {
                setCupon(result.data);
                // Set filter options based on the response data
                setFilterOptions({
                    categories: getUniqueValues(result.data, 'category_name'),
                    subcategories: getUniqueValues(result.data, 'subcategory_name'),
                    dates: getUniqueValues(result.data, 'startdate'),
                    times: getUniqueValues(result.data, 'startdate'), // Example, could be extracted from time if required
                    deals: getUniqueValues(result.data, 'cuponused'),
                });
            } else {
                // Handle errors accordingly
                setErrors({ message: 'Failed to fetch data' });
            }
        } catch (err) {
            setErrors(err); // Store error for debugging
        } finally {
            setLoading(false);
        }
    };

    // Helper function to extract unique values from an array of objects
    const getUniqueValues = (data, key) => {
        return [...new Set(data.map(item => item[key]))];
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const filteredCupon = cupon.filter(c => {
        return (
            (!filters.categories || c.category_name === filters.categories) &&
            (!filters.subcategories || c.subcategory_name === filters.subcategories) &&
            (!filters.date || c.startdate === filters.date) &&
            (!filters.time || c.startdate === filters.time) &&
            (!filters.deals || c.cuponused === filters.deals)
        );
    });

    return (
        <div className="used-cupon-outer">
            <div className="container">
                <div className="used-cupon-inner">
                    <div className="row">
                        <div className="col-3">
                            <div className="heading">
                                <h3>Filter</h3>
                            </div>
                            <div className="filter-option">
                                {/* Category Filter */}
                                <div className="input-group">
                                    <select name="categories" onChange={handleFilterChange}>
                                        <option value="" selected disabled>Select categories</option>
                                        {filterOptions.categories.map((category) => (
                                            <option key={category} value={category}>
                                                {category}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Subcategory Filter */}
                                <div className="input-group">
                                    <select name="subcategories" onChange={handleFilterChange}>
                                        <option value="" selected disabled>Select Sub - categories</option>
                                        {filterOptions.subcategories.map((subcategory) => (
                                            <option key={subcategory} value={subcategory}>
                                                {subcategory}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Date Filter */}
                                <div className="input-group">
                                    <select name="date" onChange={handleFilterChange}>
                                        <option value="" selected disabled>Select Date</option>
                                        {filterOptions.dates.map((date) => (
                                            <option key={date} value={date}>
                                                {date}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                {/* Time Filter */}
                                {/* <div className="input-group">
                                    <select name="time" onChange={handleFilterChange}>
                                        <option value="" selected disabled>Select Time</option>
                                        {filterOptions.times.map((time) => (
                                            <option key={time} value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </div> */}

                                {/* Deals Filter */}
                                <div className="input-group">
                                    <select name="deals" onChange={handleFilterChange}>
                                        <option value="" selected disabled>Select Deals</option>
                                        {filterOptions.deals.map((deal) => (
                                            <option key={deal} value={deal}>
                                                {deal}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-9">
                            <div className="heading">
                                <h3>Used Coupons</h3>
                            </div>
                            {loading ? (
                                <Loader />
                            ) : cupon.length === 0 ? (
                                <p>No used coupons available.</p>
                            ) : (
                                <div className="cupon-row">
                                    <div className="row">
                                        {filteredCupon.map((i) => (
                                            <div className="item col-12 col-md-6 col-xl-4" key={i.id}>
                                                <div className="item-inner">
                                                    <div className="img-content">
                                                        <div className="user-info">
                                                            <img src={i.profile_image || '/assets/Image/user6.jpeg'} alt={i.user_name} />
                                                            <div className="name">{i.user_name}</div>
                                                        </div>
                                                        <div className="compny-info">
                                                            <img src={i.image_url} alt={i.name} />
                                                            <div className="name">{i.shop_name}</div>
                                                        </div>
                                                    </div>
                                                    <div className="text-content row">
                                                        <div className="col-6"><b>Name:</b> {i.name}</div>
                                                        <div className="col-6"><b>Category:</b> {i.category_name}</div>
                                                        <div className="col-6"><b>Started:</b> {i.startdate}</div>
                                                        <div className="col-6"><b>Sub-Category:</b> {i.subcategory_name}</div>
                                                        <div className="col-6"><b>Ended:</b> {i.enddate}</div>
                                                        <div className="col-6"><b>Deals:</b> {i.cuponused}</div>
                                                        <div className="col-6"><b>Type:</b> {i.ctype}</div>
                                                        <div className="col-6"><b>Date:</b> {i.startdate}</div>
                                                        {/* <div className="col-6"><b>Time:</b> {i.startdate}</div> */}
                                                        <div className="col-12"><b>Purchase amount:</b> {i.purchase_amount}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UsedCupon;
