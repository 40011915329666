import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaDownload } from 'react-icons/fa6';
import ApiService from '../../../services/Api';
import { Spin } from 'antd'; 
import Loader from '../../Loader';
const OperatorDescription = () => {
    const { couponid } = useParams();
    const [couponData, setCouponData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitError, setSubmitError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCouponDetails = async () => {
            setLoading(true);
            try {
                const result = await ApiService.getadsDetailcoupon(couponid);
                if (result.status === 200 || result.status === 201) {
                    setCouponData(result.data);
                } else {
                    console.error("Failed to fetch coupon details");
                }
            } catch (err) {
                if (err.errors && err.status === 422) {
                    const backendErrors = err.errors
                    console.log(backendErrors);
                    const newErrors = {};
                    backendErrors.forEach((error) => {
                        const fieldName = error.split(' ')[1].toLowerCase(); 
                        newErrors[fieldName] = error;
                    });
                    setSubmitError(newErrors);
                }else if (err.errors && err.status === 404) {
                    navigate('/');
                } else {
                    setSubmitError(err.message || 'An unexpected error occurred.');
                }
            } finally {
                setLoading(false);
            }
        };

        if (couponid) {
            fetchCouponDetails();
        }
    }, [couponid]);

    const handleDownloadCouponsExcel = async (id, type) => {
        try {
            const blob = await ApiService.downloadExcel(id, type); 
            const url = window.URL.createObjectURL(new Blob([blob]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `coupons-${id}-${type}.xlsx`); 
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
        } catch (error) {
            console.error("Error downloading the file:", error);
        }
    };
    

    if (loading) {
        
        return <Loader />;
    }

    const imageUrl = couponData?.image_url || '/assets/Image/no-image-icon-23485.png';

    const slider = [
        { img: couponData?.image_url || '/assets/Image/no-image-icon-23485.png' },
        { img: couponData?.image_url || '/assets/Image/no-image-icon-23485.png' },
        { img: couponData?.image_url || '/assets/Image/no-image-icon-23485.png' },
    ];

    const items = [
        { heading: 'Categories', info: couponData?.category_name || "N/A" },
        { heading: 'Sub - categories', info: couponData?.subcategory_name || "N/A" },
        { heading: 'No of year trading', info: couponData?.trading_year || "N/A" },
        { heading: 'Business type', info: couponData?.btype || "N/A" },
        { heading: 'Business Relationship', info: couponData?.businessrelation || "N/A" },
        { heading: 'Restrictions', info: couponData?.registrationnumber || "N/A" },
        { heading: 'Coupon Type', info: couponData?.ctype || "N/A" },
        { heading: 'Coupons Used on', info: couponData?.cuponused || "N/A" },
        { heading: 'Available coupons', info: couponData?.couponcount || "N/A" },
        { heading: 'Started Deals', info: couponData?.startdate || "N/A" },
        { heading: 'Ended Deals', info: couponData?.enddate || "N/A" },
        { heading: 'Get Discount', info: couponData?.discamount || "N/A" },
        { heading: 'Min-Purchase', info: couponData?.miniamount || "N/A" },
        { heading: 'Max-Purchase', info: couponData?.maxamount || "N/A" },
        { heading: 'Website link', info: couponData?.website || "N/A" },
    ];

    return (
        <>
            <div className="cupon-description-outer">
                <div className="container">
                    <div className="cupon-description-inner">
                        <div className="heading">
                            <h3>{couponData?.businessrelation || "Loading..."}</h3>
                            <Link className='button'>{couponData?.cuponused}</Link>
                        </div>

                        <div className="img-cupon-content row">
                            <div className="col-md-8 col-12 img-content">
                                <div className="row">
                                     <div className="col-3 img-group">
                                        {couponData.image_url.map((image, index) => {
                                                const fixedFullPath = image.full_path.replace(/\\\//g, '/');
                                                return (
                                                    <div className="img-content mt-2" key={index}>
                                                            <img src={fixedFullPath} alt={`Image ${index}`} />
                                                    </div>
                                                );
                                            })}
                                           
                                    </div>
                                    <div className="col-12 col-lg-9 slider-img">
                                    <OwlCarousel className='owl-theme' loop margin={10} items={1} nav={false}>
                                            {couponData.image_url.map((image, index) => {
                                                const fixedFullPath = image.full_path.replace(/\\\//g, '/');
                                                return (
                                                    <div className="img-item" key={index}>
                                                        <div className="img-inner">
                                                            <img src={fixedFullPath} alt={`Image ${index}`} />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-12">
                                <div className="adspromotion-card">
                                    <div className="adpromotion-inner">
                                        <div className="heading">
                                            <h3>Ads promotion</h3>
                                        </div>
                                        <p>Did you want first preference for your coupons sale</p>
                                        <div className="apply-button">
                                            <Link className='button'>Apply</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="note">
                        {couponData.published !== 1 && (
                            <p><b>Note</b>: When you publish this coupon you can get some loyalty points.</p>)}
                              {couponData.published == 1 && (
                            <p><b>loyalty Points </b>: {couponData.totalLoayltyPoints}  <img src="/assets/Image/Coins.png" alt="Coins Logo" width={"30px"} height={"30px"} /></p>
                        )}
                        </div>

                        <ul className="services-info row">
                            {items.map((item, index) => (
                                <li className="col-12 col-md-4" key={index}>
                                    <p><b>{item.heading}</b>: <span>{item.info}</span></p>
                                </li>
                            ))}
                        </ul>

                        <div className="description">
                            <div className="heading">
                                <h4>Description</h4>
                            </div>
                            <p>{couponData.description || "Loading..."}</p>
                        </div>

                        <div className="terms-condition row">
                            <div className="col-12 col-md-6 item">
                                <div className="heading">
                                    <h4>Terms & conditions</h4>
                                </div>
                                <ul>
                                    <li>{couponData.condition || "No conditions available."}</li>
                                </ul>
                            </div>
                            <div className="col-12 col-md-6 item">
                                <div className="heading">
                                    <h4>How to use</h4>
                                </div>
                                <p>{couponData.howusecupon || "No usage instructions available."}</p>
                            </div>
                        </div>
                        {couponData.is_approved === 1 && (
                        <div className="button-group">
                            <Link className="download-button"onClick={() => handleDownloadCouponsExcel(couponData.id, 'all')}><span><FaDownload /></span>Download Coupons</Link>
                            <Link className="used-button" onClick={() => handleDownloadCouponsExcel(couponData.id, 'used')}><span><FaDownload /></span>Used Coupons</Link>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default OperatorDescription;
