import React, { useEffect, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ApiService from '../../../services/Api';
import { Spin } from 'antd'; 
import Loader from '../../Loader';
import { useNavigate } from 'react-router-dom';

const Operatormanager = () => {
    const [managers, setManagers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        handleGetManagersData();
    }, []);

    const handleGetManagersData = async () => {
        try {
            const result = await ApiService.getOperatorsData(); // Adjust this method as per your API
            if (result.status === 200 || result.status === 201) {
                setManagers(result.data);
            } else {
                // Handle failure (optional)
            }
        } catch (err) {
            if (err.status === 422) {
                setErrors(err.errors);
            } else {
                // Handle general errors (optional)
            }
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteManager = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this manager?');
        if (confirmed) {
            try {
                const result = await ApiService.deleteOperator(id);
                if (result.status === 200) {
                    // Remove the deleted manager from the state immediately
                    setManagers((prevManagers) => prevManagers.filter(manager => manager.id !== id));
                } else {
                    setErrors('Failed to delete manager.');
                }
            } catch (err) {
                setErrors('Error deleting manager.');
            }
        }
    };

    return (
        <div className="operator-manager-outer create-operator-login">
            <div className="container">
                <div className="operator-manager-inner">
                    <div className="row">
                        {loading ? (
                             <Loader />
                        ) : errors ? (
                            <p>Error: {errors}</p>
                        ) : managers.length === 0 ? (
                            <p>No Managers available.</p>
                        ) : ( 
                            managers.map((manager) => (
                                <div className="item col-12 col-sm-6 col-md-4 col-xl-3" key={manager.id}>
                                    <div className="item-inner">
                                        <div className="img-content">
                                            <img src={manager.image_url || 'assets/Image/no-image-icon-23485.png'} alt={manager.name} />
                                            <div className="user-post">{manager.roles[0].name}</div>
                                            <div 
                                                    className="edit" 
                                                    onClick={() => navigate(`/edit-operator/${manager.id}`)} // Use navigate to redirect
                                                >
                                                    <FaRegEdit />
                                                </div>
                                        </div>
                                        <div className="text-content">
                                            <div className="name">Name: {manager.name}</div>
                                            <div className="email">E-mail ID: {manager.email}</div>
                                        </div>
                                        <div className="button-groups">
                                            <button
                                                className="delete"
                                                onClick={() => handleDeleteManager(manager.id)}
                                            >
                                                Delete
                                            </button>
                                            <Link className="button" to={`/edit-operator/${manager.id}`}>View</Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Operatormanager;
