import React, { useEffect, useState } from 'react';
import { FaCirclePlus } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import ApiService from '../../../services/Api';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Spin } from 'antd';
import Loader from '../../Loader';

const Cupons = () => {
    const [coupon, setCoupon] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        handleGetCouponData();
    }, []);

    const handleGetCouponData = async () => {
        try {
            const result = await ApiService.getCouponData(); 
            if (result.status === 200 || result.status === 201) {
                setCoupon(result.data);
                setShow(true);
            } else {
               
            }
        } catch (err) {
            if (err.status === 422) {
                setErrors(err.errors); 
            }
            else if(err.status === 404) {
                setErrors(err.errors);
            }
            else {
                
            }
        } finally {
            setLoading(false); 
        }
    };

    const options = {
        loop: false, 
        responsiveClass: true,
        nav: false,
        dots: false,
        margin: 20,
        autoplay: false,
        responsive: {
            0: { items: 1 },
            767: { items: 2 },
            1200: { items: 3 },
        },
    };
    

    const getStatus = (status) => {
        switch (status) {
            case 0:
                return { text: 'Pending', className: 'pending' };
            case 1:
                return { text: 'Approved', className: 'approved' };
            case 2:
                return { text: 'Rejected', className: 'rejected' };
            default:
                return { text: 'Unknown', className: 'unknown' };
        }
    };
 
    return (
        <>
            <div className="merchant-cupon">
                <div className="container">
                    <div className="merchant-cupon-inner">
                        <div className="heading">
                            <h3>Your coupons</h3>
                            <Link to={'/newdeals'}><FaCirclePlus /> Add new deals</Link>
                        </div>

                        <div className="cupon-row">
                        {loading ? (
                             <Loader />
                            
                            ) : coupon.length === 0 ? (
                                <p>No coupons available.</p>
                            ) : (
                                <OwlCarousel className='owl-theme' {...options}>
                                {coupon.map((i) => {
                                    const { text, className } = getStatus(i.is_approved);
                                    return (
                                        <div className="item" key={i.id}>
                                            <div className="item-inner">
                                                <div className="cupon-status">
                                                    <span className={className}>{text}</span>
                                                </div>
                                                <div className="top">
                                                    <div className="img-content">
                                                        {/* <img src={i.image_url} alt="image" /> */}
                                                        <img src={i.image_url ? i.image_url : '/assets/Image/no-image-icon-23485.png'} 
                                                            alt={i.name} 
                                                        />
                                                        <h2>{i.name}</h2>
                                                    </div>
                                                    <div className="cupon-mode">
                                                        {i.cuponused}
                                                    </div>
                                                </div>
                                                <div className="bottom row">
                                                    <div className="col-6"> <b> Cat: </b> {i.category_name}</div>
                                                    <div className="col-6"><b> Started:: </b>  {i.startdate}</div>
                                                    <div className="col-6"><b>Sub-Cat: </b> {i.subcategory_name}</div>
                                                    <div className="col-6"><b> Ended: </b>  {i.enddate}</div>
                                                    <div className="col-6"><b> Deals: </b>  {i.ctype}</div>
                                                    <div className="col-6"><Link to={`/mangeradsprmotion/${i.id}`}>View</Link></div>
                                                </div>
                                            </div>
                                        </div>
                                    ); 
                                })}
                            </OwlCarousel>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Cupons;