import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FaRegEyeSlash } from 'react-icons/fa6';
import ApiService from '../../services/Api'; // Adjust the import based on your file structure

const ScanerModel = () => {
    const [userData, setUserData] = useState({}); // Initialize as empty object
    const [loading, setLoading] = useState(true);

    const fetchUserDetails = async () => {
        setLoading(true);
        try {
            const result = await ApiService.getUserDetails(); // Adjust API method
            console.log(result); // Log the entire result
            if (result.status === 200 || result.status === 201) {
                console.log("User data fetched:", result.data); // Log fetched user data
                setUserData(result.data); // Assuming data is a single user object
            } else {
                console.error("Failed to fetch user details");
            }
        } catch (err) {
            console.error("Error fetching user details:", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserDetails();
    }, []);

    return (
        <div className="scaner-outer">
            <div className="scaner-inner">
                {loading ? ( // Show loading state while fetching
                    <div>Loading...</div>
                ) : (
                    <OwlCarousel className='owl-theme' margin={10} items={1} nav={false} dots>
                        <div className="item">
                            <div className="item-inner">
                                <div className="logo-content">
                                    <img src='/assets/Image/logo.png' alt="" />
                                </div>
                                <div className="img-content">
                                    <img src='/assets/Image/QR-Code.png' alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-inner">
                                <div className="logo-content">
                                    <img src='/assets/Image/logo.png' alt="" />
                                </div>
                                <div className="code">
                                    <span><FaRegEyeSlash /></span>
                                    <p>
                                        {userData.unique_number 
                                            ? userData.unique_number.replace(/(\d{4})(?=\d)/g, '$1 ') 
                                            : 'N/A'}
                                    </p>
                                </div>
                                <div className="date-id">
                                    <div className="expiration">
                                        <span>Expiration</span>
                                        <h4>{userData.expiration_date || 'N/A'}</h4>
                                    </div>
                                    <div className="referelid">
                                        <span>Referral ID</span>
                                        <h4>{userData.referral_code || 'N/A'}</h4>
                                    </div>
                                </div>
                                <div className="custoumer-name">
                                    <h3>{userData.name || 'N/A'}</h3>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                )}
            </div>
        </div>
    );
};

export default ScanerModel;
