import React, { useEffect, useState } from 'react';
import PageBanner from '../PageBanner';
import CreateOperatorlogin from './Desktop/CreateOperatorlogin';
import Cupons from './Desktop/Cupons';
import DraftCupon from './Desktop/DraftCupon';
import Statistics from './Desktop/Statistics';
import Subscription from './Desktop/Subscription';
import OperatorHeader from './OperatorHeader';

const OperatorDesktop = () => {
  
  const [subManager, setSubManager] = useState(null);

  useEffect(() => {
    const storedSubManager = localStorage.getItem('sub_manager');
    console.log('Stored sub_manager:', storedSubManager); // Debugging line
    setSubManager(storedSubManager);
  }, []);

  useEffect(() => {
    console.log('Current subManager state:', subManager); // Debugging line
  }, [subManager]);

  return ( 
    <>
      <OperatorHeader />
      <PageBanner title='Manager' />
      <Cupons />
      <DraftCupon />
      <Subscription />
      {/* <Statistics />  */}
      
      {subManager !== '1' && <CreateOperatorlogin />}
    </>
  );
};

export default OperatorDesktop;
