import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Spin } from 'antd';
import ApiService from '../../../services/Api'; // Adjust the import to your API service
import Loader from '../../Loader';
// Default image in case the API doesn't return an image
const defaultImage = '/assets/Image/no-image-icon-23485.png';

const Exclusive = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  // Carousel options (same as in your original component)
  const options = {
    loop: false,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      767: {
        items: 3,
      },
      1024: {
        items: 4,
      },
    },
  };

  useEffect(() => {
    const fetchExclusiveData = async () => {
      try {
        const result = await ApiService.getExclusiveItems(); // Call your API to fetch data
        if (result.status === 200 || result.status === 201) {
          setItems(result.data); // Assuming the API returns an array of exclusive items
        } else {
          console.error('Failed to fetch exclusive items');
        }
      } catch (err) {
        console.error('Error fetching exclusive items:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchExclusiveData();
  }, []); // Empty dependency array to run once on component mount

  return (
    <div className="exclusive-outer">
      <div className="container">
        <div className="exclusive-inner">
          {loading ? (
                   <Loader />
          ) : (
            <OwlCarousel className="owl-theme" {...options}>
              {/* If no items are fetched, show the default image */}
              {(items.length > 0 ? items : [{ id: 'default', image_url: defaultImage, name: 'No items available' }]).map((item) => {
                const imageUrl = item.image_url || defaultImage; // Fallback image URL if API doesn't return one

                return (
                  <div className="item" key={item.id}>
                    <div className="item-inner">
                      <div className="img-content">
                        <img
                          src={imageUrl}
                          alt={item.name || 'Exclusive item'}
                          className="exclusive-image"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = defaultImage; // Set fallback image if there's an error
                          }}
                        />
                      </div>
                      <div className="text-content">
                        <p>
                          <span>EXCLUSIVE</span> {item.name}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          )}
        </div>
      </div>
    </div>
  );
};

export default Exclusive;
