import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom'
import ApiService from '../../../../services/Api'; // Assuming you have an API service for data submission
import Loader from '../../../Loader';
import { Spin } from 'antd'; // For showing a loading spinner
import SuccessfulModal from './SuccessfulModal';
const Addressing = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    address: '',
    landmark: '',
    pinCode: '',
  });
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false); // To toggle between edit and view mode
  const [termsAccepted, setTermsAccepted] = useState(false); 
  // Fetch existing address data if available
  useEffect(() => {
    const fetchAddressData = async () => {
      try {
        const result = await ApiService.getUserProfile(); // Assuming ApiService.getUserAddress fetches the current address
        if (result.status === 200 || result.status === 201) {
          setFormData({
            name: result.data.name || '',
            mobile: result.data.mobile || '',
            address: result.data.address || '',
            landmark: result.data.landmark || '',
            pinCode: result.data.pincode || '',
          });
        }
      } catch (err) {
        setErrors({ message: err.message || 'Failed to fetch address data' });
      }
    };

    fetchAddressData();
  }, []);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCheckboxChange = () => {
    setTermsAccepted((prev) => !prev); // Toggle checkbox state
  };
  // Validation function
  const validateForm = () => {
    const errors = {};

    // Name Validation
    if (!formData.name) {
      errors.name = 'Name is required';
    }

    // Mobile Validation
    if (!formData.mobile) {
      errors.mobile = 'Mobile number is required';
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      errors.mobile = 'Mobile number must be 10 digits';
    }

    // Address Validation
    if (!formData.address) {
      errors.address = 'Address is required';
    }

    // Landmark Validation
    if (!formData.landmark) {
      errors.landmark = 'Landmark is required';
    }

    // Pin Code Validation
    if (!formData.pinCode) {
      errors.pinCode = 'Pin code is required';
    } else if (!/^\d{6}$/.test(formData.pinCode)) {
      errors.pinCode = 'Pin code must be 6 digits';
    }
    if (!termsAccepted) {
      errors.terms = 'You must agree to the cancellation policy before submitting.';
    }
    setErrors(errors); // Update errors state
    return Object.keys(errors).length === 0; // If no errors, return true
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Do not proceed if the form is invalid
    }

    setLoading(true);
    const productId = localStorage.getItem('productId');

    if (productId) {
        // Add productId to formData before sending it in the request
        formData.productId = productId;
    }
    try {
      const result = await ApiService.updateOrder(formData); // Assuming ApiService.updateUserAddress updates the address
      if (result.status === 200 || result.status === 201) {
        setSuccessMessage('Order Placed successfully!');
        
        setTimeout(() => {
          setSuccessMessage('');
          localStorage.removeItem('productId');
          window.location.href = '/';
        }, 3000); 
      } else {
        setErrors({ message: 'Failed to update address. Please try again.' });
      }
    } catch (err) {
      if (err.errors && err.status === 422) {
          const backendErrors = err.errors
          console.log(backendErrors);
          const errors = {};
          backendErrors.forEach((error) => {
              const fieldName = error.split(' ')[1].toLowerCase(); 
              errors[fieldName] = error;
          });
          setErrors(errors);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="addressing-outer">
      <div className="container">
        <div className="addressing-inner">
          <div className="heading">
            <h3>Address Details</h3>
          </div>

          {/* Show success message */}
        

          {/* Show error message */}
          {errors.message && <div className="error" style={{ color: 'red', marginBottom: '15px' }}>{errors.message}</div>}
          {successMessage ? (
                       <SuccessfulModal />
                        ) : (
          <form onSubmit={handleSubmit}>
            <div className="row">
              {/* Name Field */}
              <div className="col-12 col-sm-6">
                <div className="input-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    placeholder="Enter Name"
                    disabled={!editMode} // Disable input if not in edit mode
                  />
                </div>
                {errors.name && <span className="error">{errors.name}</span>}
              </div>

              {/* Mobile Field */}
              <div className="col-12 col-sm-6">
                <div className="input-group">
                  <label htmlFor="mobile">Mobile number</label>
                  <input
                    type="text"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                    placeholder="Enter Mobile Number"
                    disabled={!editMode} // Disable input if not in edit mode
                  />
                </div>
                {errors.mobile && <span className="error">{errors.mobile}</span>}
              </div>

              {/* Address Field */}
              <div className="col-12">
                <div className="input-group">
                  <label htmlFor="address">Address</label>
                  <textarea
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    placeholder="Enter Address"
                    disabled={!editMode} // Disable input if not in edit mode
                  ></textarea>
                </div>
                {errors.address && <span className="error">{errors.address}</span>}
              </div>

              {/* Landmark Field */}
              <div className="col-12 col-sm-6">
                <div className="input-group">
                  <label htmlFor="landmark">Landmark</label>
                  <input
                    type="text"
                    name="landmark"
                    value={formData.landmark}
                    onChange={handleInputChange}
                    placeholder="Enter Near Landmark"
                    disabled={!editMode} // Disable input if not in edit mode
                  />
                </div>
                {errors.landmark && <span className="error">{errors.landmark}</span>}
              </div>

              {/* Pin Code Field */}
              <div className="col-12 col-sm-6">
                <div className="input-group">
                  <label htmlFor="pinCode">Pin code</label>
                  <input
                    type="text"
                    name="pinCode"
                    value={formData.pinCode}
                    onChange={handleInputChange}
                    placeholder="Enter Pin Code"
                    disabled={!editMode} // Disable input if not in edit mode
                  />
                </div>
                {errors.pinCode && <span className="error">{errors.pinCode}</span>}
              </div>
            </div>

            {/* Checkbox (Optional) */}
            <div className="check-group">
              <input
                type="checkbox"
                name="terms"
                id="terms"
                checked={termsAccepted}
                onChange={handleCheckboxChange} // Track checkbox state
                disabled={!editMode} // Disable checkbox if not in edit mode
              />
              <label htmlFor="terms">
                Once you submit the order, there is no cancellation option.
              </label>
          
            </div>
            {errors.terms && <span className="error">{errors.terms}</span>}
            {/* Submit Button */}
            <div className="input-button">
              {editMode ? (
                <button type="submit" className="button" disabled={loading}>
                  {loading ? <Spin size="small" /> : 'Save'}
                </button>
              ) : (
                <button
                  type="button"
                  className="button"
                  onClick={() => setEditMode(true)} // Enable edit mode
                >
                  Edit Address
                </button>
              )}
            </div>
          </form>
             )}
        </div>
      </div>
    </div>
  );
};

export default Addressing;
