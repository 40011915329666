import React from 'react'
import MearchantHeader from './MearchantHeader'
import EditOperator from './ViewOpeartor/EditOperator'
import PageBanner from '../PageBanner'

const OperatorView = () => {
  return (
    <>
      <MearchantHeader/>
      <PageBanner title='View Operator'/>
      <EditOperator/>
    </>
  )
}

export default OperatorView
