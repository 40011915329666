import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ApiService from "../../../services/Api";
import Loader from "../../Loader";

const CashBackOfferWishList = () => {
  const [cashbackOffers, setCashbackOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCashbackOffers = async () => {
      setLoading(true);
      try {
        const result = await ApiService.getwishlisttypeData("cashback"); // Replace with your API endpoint
        setCashbackOffers(result.data); // Assuming the data is in `data`
      } catch (err) {
        if (err.status === 404) {
          setError("Wihslit Cashback offers not found.");
        } else if (err.status === 422) {
          setError("Invalid data received.");
        } else {
          setError("Failed to load cashback offers.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchCashbackOffers();
  }, []); // Empty dependency array to run effect only once on component mount

  if (loading) {
    return <Loader />; // Render a loading spinner if data is still fetching
  }

  // if (error) {
  //   return <div>{error}</div>; // Display error if something goes wrong
  // }

  return (
    <div className="cashback-store">
      <div className="container">
        <div className="cashback-store-inner">
          <div className="heading">
            <h3>Cashback Offers</h3>
          </div>
          <div className="row cashback">
            {cashbackOffers.map((offer, index) => (
              <div className="item" key={index}>
                <div className="item-inner">
                <Link to={`/cupondescription/${offer.id}`}>
                  <div className="img-content">
                    <img
                      src={offer.image_url ? offer.image_url : '/assets/Image/no-image-icon-23485.png'}
                      alt={offer.name || 'Cashback Offer'}
                    />
                  </div>
                  <div className="text-content">
                    <p>
                      {offer.amounttype === 'percentage'
                        ? `Up to ${Math.round(offer.discamount)}% off`
                        : `Up to $${Math.round(offer.discamount)} off`}
                    </p>
                    <div className="cupon-button">
                      <Link to={`/cupondescription/${offer.id}`} className="cupon-code-button">
                        <span className="cupon">Get Coupons</span>
                        <span className="code">{offer.coupon_code || 'N/A'}</span>
                      </Link>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashBackOfferWishList;
