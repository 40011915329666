import React from 'react';
import ApplyMerchant from './LoginRegister/ApplyMerchant';
import MearchantHeader from './MearchantHeader';
import PageBanner from '../PageBanner';
import ApprovalPandding from '../Merchant/LoginRegister/ApprovalPandding';
import ApprovalRejected from '../Merchant/ApprovalRejected';
import { Navigate } from 'react-router-dom';

import MerchantDesktop from '../Merchant/MerchantDesktop';
const MeachantLoginPage = () => { 
  const role = localStorage.getItem('role'); 
  if (!role) {
    alert("please login to continue")
    return <Navigate to="/home" />;
  }
  const approvalStatus = localStorage.getItem('is_approved');
  return (
    <>
    {approvalStatus !== '2' && <MearchantHeader />}
    {approvalStatus !== '2' && <PageBanner title='Merchants Login' />}
    {approvalStatus === '0' && <ApplyMerchant />}  
    {approvalStatus === '1' && <ApprovalPandding />} 
    {approvalStatus === '2' && <MerchantDesktop />}
    {approvalStatus === '3' && <ApprovalRejected />}
  </>
  );
}

export default MeachantLoginPage;
