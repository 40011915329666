import React, { useEffect, useState } from 'react';
import { FaCircleCheck } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import ApiService from '../../services/Api';
import { Spin } from 'antd'; 
import Loader from '../Loader';
const SubscriptionPlan = () => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null);

    const handleGetPlanData = async () => {
        try {
            const result = await ApiService.getSubscriptionPlans(); // Replace this with your actual API method
            if (result.status === 200 || result.status === 201) {
                setItems(result.data); // Assuming the API returns plan data in `result.data`
            } else {
                setError('Failed to load plans.');
            }
        } catch (err) {
            if (err.status === 422) {
                setError('Validation errors occurred.');
            } else {
                setError('An error occurred while fetching plans.');
            }
        } finally {
            setLoading(false); // Set loading to false after the data is fetched
        }
    };

    useEffect(() => {
        handleGetPlanData(); // Fetch plans when the component loads
    }, []);

    if (loading) {
        
        return <Loader />;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>
            <div className="sub-plan-outer">
                <div className="container">
                    <div className="sub-plan-inner">
                        <div className="heading">
                            <h3>Choose your best plan</h3>
                        </div>
                        <div className="row">
                            {items.map((i, index) => (
                                <div key={index} className="item col-lg-4 col-12">
                                    <div className="item-inner">
                                        <h3 style={{ color: `${i.color}` }}>{i.plan_name}</h3>
                                        <div className="img-content">
                                            <img src={i.original_url} alt={i.plan_name} />
                                        </div>
                                        <div className="rupees">
                                            <span className="ruppes-icon">₹ </span>
                                            <span className="rupess-text">{i.price}</span>
                                            <span className="monthly-plan"> PER MONTH</span>
                                        </div>
                                        <ul className="plan-description">
                                            <li>
                                                <span style={{ color: `${i.color}` }}>
                                                    <FaCircleCheck />
                                                </span> {i.description}
                                            </li>
                                            {/* <li>
                                                <span style={{ color: `${i.textcolor}` }}>
                                                    <FaCircleCheck />
                                                </span> {i.description2}
                                            </li> */}
                                            {/* <li>
                                                <span style={{ color: `${i.textcolor}` }}>
                                                    <FaCircleCheck />
                                                </span> {i.description3}
                                            </li>
                                            <li>
                                                <span style={{ color: `${i.textcolor}` }}>
                                                    <FaCircleCheck />
                                                </span> {i.description4}
                                            </li> */}
                                        </ul>
                                        <Link to={`/paymentdetails/${i.id}`} className="button" style={{ backgroundColor: `${i.color}` }}>
                                            Subscribe
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubscriptionPlan;
