import React from 'react'
import { FaRegEdit } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Operatormanager = () => {
    const manager = [
        {
            img: 'assets/Image/manager.png',
            name: 'Sanjay',
            post: 'Manager',
            email: 'Sanjay4@gmail.com'
        },
        {
            img: 'assets/Image/manager.png',
            name: 'Sanjay',
            post: 'Manager',
            email: 'Sanjay4@gmail.com'
        },
        {
            img: 'assets/Image/manager.png',
            name: 'Sanjay',
            post: 'Manager',
            email: 'Sanjay4@gmail.com'
        },
        {
            img: 'assets/Image/manager.png',
            name: 'Sanjay',
            post: 'Manager',
            email: 'Sanjay4@gmail.com'
        },
        {
            img: 'assets/Image/manager.png',
            name: 'Sanjay',
            post: 'Manager',
            email: 'Sanjay4@gmail.com'
        },
        {
            img: 'assets/Image/manager.png',
            name: 'Sanjay',
            post: 'Manager',
            email: 'Sanjay4@gmail.com'
        },
        {
            img: 'assets/Image/manager.png',
            name: 'Sanjay',
            post: 'Manager',
            email: 'Sanjay4@gmail.com'
        },
        {
            img: 'assets/Image/manager.png',
            name: 'Sanjay',
            post: 'Manager',
            email: 'Sanjay4@gmail.com'
        },
        {
            img: 'assets/Image/manager.png',
            name: 'Sanjay',
            post: 'Manager',
            email: 'Sanjay4@gmail.com'
        },
        {
            img: 'assets/Image/manager.png',
            name: 'Sanjay',
            post: 'Manager',
            email: 'Sanjay4@gmail.com'
        },
        {
            img: 'assets/Image/manager.png',
            name: 'Sanjay',
            post: 'Manager',
            email: 'Sanjay4@gmail.com'
        },
    ]

    return (
        <>
            <div className="operator-manager-outer create-operator-login ">
                <div className="container">
                    <div className="operator-manager-inner">
                        <div className="row">


                            {manager.map((i) => {
                                return (
                                    <>
                                        <div className="item col-12 col-sm-6 col-md-4 col-xl-3">
                                            <div className="item-inner">
                                                <div className="img-content">
                                                    <img src={i.img} alt="" />
                                                    <div className="user-post">{i.post}</div>
                                                    <div className="edit"><FaRegEdit /></div>
                                                </div>
                                                <div className="text-content">
                                                    <div className="name">Name : {i.name}</div>
                                                    <div className="email">E-mail ID : {i.email}</div>
                                                </div>
                                                <div className="button-groups">
                                                    <Link className="delete">Delete</Link>
                                                    <Link className="button">View</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Operatormanager
