import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ApiService from '../../../services/Api'; // Assuming you have an ApiService to handle API calls

const CircularProgressBar = () => {
    const { couponid } = useParams();
    const [couponData, setCouponData] = useState(null); // Store the coupon data
    const [loading, setLoading] = useState(true); // To show loading state
    const [errors, setErrors] = useState(null); // To handle errors

    // Fetch coupon data based on the couponId from the URL
    const handleGetCouponData = async () => {
        try {
            const result = await ApiService.CirculerData(couponid); // Pass the 'id' to the API method
            if (result.status === 200 || result.status === 201) {
                setCouponData(result.data); // Set the fetched data to state
            } else {
                setErrors('Error fetching data');
            }
        } catch (err) {
            if (err.status === 422) {
                setErrors('Validation Error');
            } else if (err.status === 404) {
                setErrors('Data not found');
            } else {
                setErrors('An error occurred');
            }
        } finally {
            setLoading(false); // Set loading to false after the request is complete
        }
    };

    useEffect(() => {
        if (couponid) { 
            handleGetCouponData(); 
        }
    }, [couponid]); 

    // Handle loading state
    if (loading) {
        return <div>Loading...</div>;
    }

    // Handle errors if any
    if (errors) {
        return <div>{errors}</div>; // Display errors if any
    }

    return (
        <div className="static-progress-bar">
            {couponData && (
                <div className="progress-outer">
                    <div className="progress-inner">
                        <CircularProgressbar
                            value={couponData.percentage} // Use percentage from API data
                            text={`${couponData.percentage}%`}
                            strokeWidth={20}
                            styles={{
                                path: {
                                    stroke: couponData.color, // Use color from API data
                                },
                            }}
                        />
                        <div className="text-content">
                            <p>{couponData.title}</p> {/* Use title from API data */}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CircularProgressBar;
