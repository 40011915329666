import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApiService from '../../../services/Api'; // Your API service
import { Spin } from 'antd'; // For loading indicator
import Loader from '../../Loader'; // Loader component for UI

const ChangePassword = ({ show, close }) => {
    const MAX_PASSWORD_LENGTH = 12; // Set your max password length

    // State hooks for form inputs and validation/error messages
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [newErrors, setNewErrors] = useState({});

    // Handle input change for password fields
    const handleChangePassword = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Form submission handler
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation object to capture errors
        let newErrors = {};

        // Validate old password
        if (!formData.oldPassword) {
            newErrors.oldPassword = 'Old password is required';
        }

        // Validate new password
        if (!formData.newPassword) {
            newErrors.newPassword = 'Password is required';
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,12}$/.test(formData.newPassword)) {
            newErrors.newPassword = `Password must be between 8 and ${MAX_PASSWORD_LENGTH} characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.`;
        }

        // Validate confirm password
        if (!formData.confirmPassword) {
            newErrors.confirmPassword = 'Please confirm your password';
        } else if (formData.newPassword !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
        }

        // If there are validation errors, do not proceed with the API call
        if (Object.keys(newErrors).length > 0) {
            setNewErrors(newErrors);
            return;
        }

        // Clear previous errors
        setError(null);
        setLoading(true); // Show loading spinner

        try {
            // Assuming there's an API service to update the password
            const response = await ApiService.changePassword({
                oldPassword: formData.oldPassword,
                newPassword: formData.newPassword,
                confirmPassword: formData.confirmPassword
            });

            if (response.status === 200) {
                setSuccessMessage('Password changed successfully!');
                setTimeout(() => {
                    setSuccessMessage('');
                    close(); // Close the modal
                }, 3000); // Del
            }
        } catch (err) {
            if (err.response && err.response.status === 400) {
                setError(err.response.data.errors || 'Failed to change password.');
            } else if (err.response && err.response.status === 422) {
                // Handling 422 validation error response
                const backendErrors = err.response.data.errors || [];
                let newErrors = {};

                // Iterate over backend validation errors and set them to corresponding fields
                backendErrors.forEach((errorMessage) => {
                    const fieldName = errorMessage.split(' ')[1].toLowerCase(); // Extract field name from the error message
                    newErrors[fieldName] = errorMessage;
                });

                setError(newErrors);
            } else {
                setError('An unexpected error occurred.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={close}>
            <div className="change-password-outer">
                <div className="container">
                    <div className="change-password-inner">
                        <div className="change-password row">
                            <div className="col-12 col-sm-6 left">
                                <div className="img-content">
                                    <img src="assets/Image/changepassword.png" alt="Change Password" />
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 right">
                                <div className="inner">
                                    <div className="heading">
                                        <h3>Change Password</h3>
                                    </div>
                                    <form onSubmit={handleSubmit} method="post">
                                        {/* Error and success messages */}
                                        {error && (
                                            <div className="alert alert-danger">
                                                {typeof error === 'object'
                                                    ? Object.values(error).map((err, index) => (
                                                          <p key={index}>{err}</p>
                                                      ))
                                                    : error}
                                            </div>
                                        )}
                                        {successMessage && <div className="alert alert-success">{successMessage}</div>}

                                        {/* Old Password Field */}
                                        <div className="input-group">
                                            <label htmlFor="oldPassword">Old Password</label>
                                            <input
                                                type="password"
                                                name="oldPassword"
                                                id="oldPassword"
                                                placeholder="Enter your old password"
                                                value={formData.oldPassword}
                                                onChange={handleChangePassword}
                                            />
                                            {/* Display old password validation error */}
                                            {newErrors.oldPassword && <div className="text-white">{newErrors.oldPassword}</div>}
                                        </div>

                                        {/* New Password Field */}
                                        <div className="input-group">
                                            <label htmlFor="newPassword">Create Password</label>
                                            <input
                                                type="password"
                                                name="newPassword"
                                                id="newPassword"
                                                placeholder="Enter your new password"
                                                value={formData.newPassword}
                                                onChange={handleChangePassword}
                                            />
                                            {/* Display new password validation error */}
                                            {newErrors.newPassword && <div className="text-white">{newErrors.newPassword}</div>}
                                        </div>

                                        {/* Confirm Password Field */}
                                        <div className="input-group">
                                            <label htmlFor="confirmPassword">Confirm Password</label>
                                            <input
                                                type="password"
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                placeholder="Confirm your new password"
                                                value={formData.confirmPassword}
                                                onChange={handleChangePassword}
                                            />
                                            {/* Display confirm password validation error */}
                                            {newErrors.confirmPassword && <div className="text-white">{newErrors.confirmPassword}</div>}
                                        </div>

                                        <div className="submit-button">
                                            {loading ? (
                                                <Spin size="small" />
                                            ) : (
                                                <Link onClick={handleSubmit} className="button">Save</Link>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ChangePassword;
