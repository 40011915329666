import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../../services/Api';

const CuponCodeEnter = ({ onOpenUserCupon }) => {
    const [couponCode, setCouponCode] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async () => {
        // Clear any existing error messages
        setError('');

        // Validate the coupon code
        if (!couponCode.trim()) {
            setError('Please enter a coupon code.');
            return;
        }

        try {
            const result = await ApiService.verifyOnlineCouponCode({ coupon_code: couponCode });
            if (result.status === 200 || result.status === 201) {
                onOpenUserCupon(result.data);
            } else {
                setError('Invalid coupon code. Please try again.');
            }
        } catch (error) {
            if (error.status === 422) {
                setError(error.errors);
            }
        }
    };

    return (
        <div className="enter-cupon-code">
            <div className="cupon-code-info">
                <div className="heading"><h3>Enter Online coupon code</h3></div>
                <input
                    type="text"
                    placeholder='Enter coupon code'
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                />     
            </div>

            <div className="submit-button">
                <Link className="button" onClick={handleSubmit}>Submit</Link>
            </div>
            {error && <div className="error mt-3 d-flex justify-content-center">{error}</div>}
        </div>
    );
};

export default CuponCodeEnter;
