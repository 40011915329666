import { Tabs } from 'antd';
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { BiSolidDiscount } from 'react-icons/bi';
import { BsQrCodeScan } from 'react-icons/bs';
import { FaIdCard } from 'react-icons/fa6';
import PageBanner from '../PageBanner'
import CuponCodeEnter from '../Shoper/OnlineCoupons/CuponCodeEnter';
import EnterPurchasingAmount from '../Shoper/OnlineCoupons/EnterPurchasingAmount';
import UserCuponOnline from '../Shoper/OnlineCoupons/UserCuponOnline';
import Scanner from '../Shoper/Scanner/Scanner';
import SuccessfulModal from '../Shoper/Scanner/SuccessfulModal';
import ShoperHeader from '../Shoper/ShoperHeader'
import UsedCupon from '../Shoper/UsedCupon/UsedCupon';

const CashierDashboard = () => {
    const [activeKey, setActiveKey] = useState('1');
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const handleClose = () => setShowModal(false);

    const handleCuponCodeEnter = () => {
        setModalContent(<CuponCodeEnter onClose={handleClose} onOpenUserCupon={handleUserCupon} />)
        setShowModal(true)
    }

    const handleUserCupon = () => {

        setModalContent(<UserCuponOnline onClose={handleClose} onOpenPurchasing={handlePurchasing} />);
        setShowModal(true);
    };

    const handlePurchasing = () => {
        setModalContent(<EnterPurchasingAmount onClose={handleClose} onOpenSuccessfull={handleSuccessfull} />)
    }
    const handleSuccessfull = () => {
        setModalContent(<SuccessfulModal onClose={handleClose} />)
    }

    const handleTabChange = (key) => {
        if (key === '3') {
            handleCuponCodeEnter();
            setActiveKey('1');
        } else {
            setActiveKey(key);
        }
    }
  return (
    <>
        <ShoperHeader/>
        <PageBanner title='Cashier'/>
        <Tabs
                className='dashbordheader'
                centered
                defaultActiveKey="1"
                activeKey={activeKey}
                onChange={handleTabChange}
                items={[
                    {
                        label: 'Scanner',
                        key: '1',
                        children: <Scanner />,
                        icon: <BsQrCodeScan />
                    },
                    {
                        label: 'Used coupons',
                        key: '2',
                        children: <UsedCupon />,
                        icon: <FaIdCard />

                    },
                    {
                        label: 'Online Upload used coupons',
                        key: '3',
                        children: '',
                        icon: <BiSolidDiscount />

                    },
                ]}
            />
            {showModal && (
                <Modal show={showModal} onHide={handleClose}>
                    {modalContent}
                </Modal>

            )}

    </>
  )
}

export default CashierDashboard
