import React from 'react'
import CreateOperatorlogin from './Desktop/CreateOperatorlogin'
import Cupons from './Desktop/Cupons'
import DraftCupon from './Desktop/DraftCupon'
// import Statistics from './Desktop/Statistics'
import Subscription from './Desktop/Subscription'
import MearchantHeader from './MearchantHeader'
import PageBanner from '../PageBanner'

const MerchantDesktop = () => {
  return ( 
    <>
    <MearchantHeader/>
      <PageBanner title='Merchant'/>
      <Cupons/>
      <DraftCupon/>
      <Subscription/>
      <CreateOperatorlogin/>
    </>
  )
}

export default MerchantDesktop
