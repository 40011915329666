import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ApiService from '../../../../services/Api'; // Adjust the path to your ApiService file
import Loader from '../../../Loader'; // Assuming you have a custom Loader component

const CashbackStore = () => {
    const [cashbackOffers, setCashbackOffers] = useState([]); // State to store cashback offers
    const [loading, setLoading] = useState(false); // State to track loading status
    const [error, setError] = useState(null); // State to track errors

    // Fetch cashback offers when component mounts
    useEffect(() => {
        const fetchCashbackOffers = async () => {
            setLoading(true); // Set loading to true before making the request
            try {
                const result = await ApiService.PurchasedCoupontype('cashback','used'); // Call API for cashback data
                setCashbackOffers(result.data); // Set the fetched data into the state
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    setError('No cashback offers found.');
                } else if (err.response && err.response.status === 422) {
                    setError('Invalid request.');
                } else {
                    setError('Failed to load cashback offers.');
                }
            } finally {
                setLoading(false); // Set loading to false after the request finishes
            }
        };

        fetchCashbackOffers(); // Call the fetch function on component mount
    }, []); // Empty dependency array to run the effect only once when component mounts

    // Show loader while fetching data
    if (loading) {
        return <Loader />;
    }

    // Show error message if there was an issue fetching data
    if (error) {
        return <div>{error}</div>;
    }
    if (cashbackOffers.length === 0) {
        return (
            <div className="no-data mt-3 mb-3">
                <h3>No Used cash back Available</h3>
                <p>Sorry, there are no cashback used at the moment.</p>
            </div>
        );
    }

    return (
        <>
            <div className="cashback-store">
                <div className="container">
                    <div className="cashback-store-inner">
                        <div className="heading">
                            <h3>cash back stores</h3>
                        </div>

                        <div className="row cashback">
                            {cashbackOffers.map((offer) => {
                                return (
                                    <div className="item" key={offer.id}>
                                        <div className="item-inner">
                                        <Link to={`/useddescription/${offer.id}`}>
                                            <div className="img-content">
                                                <img
                                                    src={offer.image_url || '/assets/Image/no-image-icon-23485.png'}
                                                    alt={offer.name}
                                                />
                                            </div>

                                            <div className="text-content">
                                                <p>
                                                    {/* Display cashback offer: percentage or dollar amount */}
                                                    {offer.amounttype === 'percentage'
                                                        ? `Up to ${Math.round(offer.discamount)}% off`
                                                        : `Up to $${Math.round(offer.discamount)} off`}
                                                </p>
                                                <div className="cupon-button">
                                                    <Link to={`/useddescription/${offer.id}`} className='cupon-code-button'>
                                                        <span className='cupon'>get Coupons</span>
                                                        <span className='code'>{(offer.unique_code)}</span>
                                                    </Link>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CashbackStore;
