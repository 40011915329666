import React, { useEffect, useState } from 'react';
import { GoPlusCircle } from "react-icons/go";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ApiService from '../../../services/Api';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const CreateOperatorlogin = () => {
    const navigate = useNavigate();
    const [operators, setOperators] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        handleGetOperatorsData();
    }, []);

    const handleGetOperatorsData = async () => {
        try {
            const result = await ApiService.getOperatorsData(); // Fetch operator data
            if (result.status === 200 || result.status === 201) {
                setOperators(result.data);
            } else {
                setErrors('Failed to fetch operators.');
            }
        } catch (err) {
            setErrors('Error fetching operators.');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteOperator = async (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this operator?');
        if (confirmed) {
            try {
                const result = await ApiService.deleteOperator(id); // Delete the operator
                if (result.status === 200 || result.status === 204) {
                    // Immediately remove the operator from the state to reflect the deletion in the UI
                    setOperators((prevOperators) => prevOperators.filter(operator => operator.id !== id));
                } else {
                    setErrors('Failed to delete operator.');
                }
            } catch (err) {
                setErrors('Error deleting operator.');
            }
        }
    };

    // Carousel options
    const options = {
        loop: false,
        responsiveClass: true,
        nav: false,
        dots: false,
        margin: 20,
        autoplay: false,
        responsive: {
            0: { items: 1 },
            576: { items: 2 },
            990: { items: 3 },
            1200: { items: 5 },
        },
    };

    return (
        <div className="create-operator-login">
            <div className="container">
                <div className="create-operator-inner">
                    <div className="heading">
                        <h3>
                            <Link to='/opertorslogin'><span><GoPlusCircle /></span> Create Operator Login</Link>
                        </h3>
                        <Link to='/opertors'>View All</Link>
                    </div>
                    <div className="manager-outer">
                        {loading ? (
                            <p>Loading...</p>  // You can replace this with a loader component if you have one.
                        ) : errors ? (
                            <p>Error: {errors}</p>
                        ) : operators.length === 0 ? (
                            <p>No Operators available.</p>
                        ) : (
                            <OwlCarousel className='owl-theme' {...options}>
                                {operators.map((operator) => (
                                    <div className="item" key={operator.id}>
                                        <div className="item-inner">
                                            <div className="img-content">
                                                <img
                                                    src={operator.image_url ? operator.image_url : '/assets/Image/no-image-icon-23485.png'}
                                                    alt={operator.name || "No image available"}
                                                />
                                                <div className="user-post">{operator.roles[0].name}</div>
                                                <div
                                                    className="edit"
                                                    onClick={() => navigate(`/edit-operator/${operator.id}`)}
                                                >
                                                    <FaRegEdit />
                                                </div>
                                            </div>
                                            <div className="text-content">
                                                <div className="name">Name : {operator.name}</div>
                                                <div className="email">E-mail ID : {operator.email}</div>
                                            </div>
                                            <div className="button-groups">
                                                <button
                                                    className="delete"
                                                    onClick={() => handleDeleteOperator(operator.id)}
                                                >
                                                    Delete
                                                </button>
                                                <Link className="button" to={`/edit-operator/${operator.id}`}>View</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateOperatorlogin;
